import React from 'react'
import {Title,Part3} from './style'
import GovernmentVisitCarousel from 'components/governmentVisitCarousel'

//30+政企校合影
export default () => {
    return <Part3>
        <Title>
            <p><span>30+</span>政企校合影</p>
        </Title>
        <GovernmentVisitCarousel/>
    </Part3>
}
