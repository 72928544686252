import React,{useEffect,useState} from 'react'
import { Title } from '../../style'
import {Main,Container,Content} from './style.js'

let data = [
    [1,2,3,4,5,6,],
    [7,8,9,10,11,12,13],
    [14,15,16,17,18,19,],
    [20,21,22,23,24,25,26],
]
export default () => {

    return <Container>
            <Main>
            <Title className='title'>
                <p>JOINT VENTURE</p>
                <p>合作企业</p>
            </Title>
            <Content>
                {
                    data.map((item,index)=>{
                        return <ul key={index}>
                            {item.map((list,index2)=>{
                                return <li key={list}>
                                    <img src={require(`assets/images/communication/enterprise/${list}.png`)}/>
                                </li>
                            })}
                        </ul>
                    })
                }
            </Content>
        </Main>
    </Container>
}