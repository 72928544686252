import React from 'react'
import Nav from 'components/nav' 
import Footer from 'components/footer'
import Banner from './children/banner'
import Environment from './children/environment'
import Map from './children/map'

export default (props) => {
    return <>
        <Nav {...props}/>
        <Banner/>
        <Environment/>
        <Map/>
        <Footer/>
    </>
}