import React,{useState} from 'react'
import {Title} from '../../style';
import {Container,Main,Content} from './style';
import LazyImg from 'components/lazyLoad/lazyImg';
 
export default () => {
    let [dom,setDom] = useState(null);
    let data = [                                          
        {
            text:'1. 薪资测评'
        },
        {
            text:'2. 职业规划'
        },
        {
            text:'3. 技能图谱'
        },
        {
            text:'4. 视频简历'
        },
        {
            text:'5. 推荐企业'
        },
        {
            text:'6. 学习推荐'
        },
        {
            text:'7. 智能匹配'
        },
        {
            text:'8. 岗位提升'
        }
    ]

    return <Container>
        <Main ref={el=>setDom(el)}>
            <Title className='title'>
                <p>TALENT EMPLOYMENT</p>
                <p>鲸聘-跨境电商人才就业系统</p>
                <p><span>4000+</span>合作企业，助力高校学生就业</p>
            </Title>
            <Content>
                <div>
                    <div>
                        <LazyImg container={dom} src={'assets/img/employment-1.png'}/>
                    </div>
                    <div className='image-2'>
                        <LazyImg container={dom} src={'assets/img/employment-2.png'}/>
                        {data.map((item,index)=><span key={index} className={`text text-${index+1}`}>{item.text}</span>)}
                    </div>
                </div>
                <div>
                    <div className='image-shadow'>
                    <p>人才就业系统用户界面</p>
                        <LazyImg container={dom} src={'assets/img/employment-3.png'}/>
                    </div>
                    <div>
                    <p>人才就业系统岗位界面</p>
                        <LazyImg container={dom} src={'assets/img/employment-4.png'}/>
                    </div>
                </div>
            </Content>
        </Main>
    </Container>
}
