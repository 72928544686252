import React, { useCallback, useEffect, useState } from 'react'
import { Main, Header, Content } from './style'
import { Pagination } from 'antd';
import Item from './item';
import {Link} from 'react-router-dom';
import AJAX from "api/api";
import LazyImg from 'components/lazyLoad/lazyImg';
let curPage = 1;
export default () => {
    let [headerData, setHeaderData] = useState([]);
    let [contentData, setContentData] = useState([]);
    let [pageSize, setPageSize] = useState(6);
    let [total, setTotal] = useState(0);
    let [container, setContainer] = useState('');

    const getList = useCallback(() => {
        AJAX.Journalism.getAllNewsList({ category: 3,page:curPage,size:6}).then(res => {
            setHeaderData(res.data.list)
            setContentData(res.data.list)
            setTotal(res.data.total)
            setPageSize(res.data.pageSize)
        })
    }, [])

    useEffect(() => {
        getList();
    }, [getList])

    function handleChange(cur) {
        curPage = cur;
        getList()
    }


    return <Main>
        <Header ref={el => setContainer(el)}>
            {
                headerData.map(({ pictureImage, createTime, title,sign}, index) => {
                    if (index < 2) {
                        return <Link to={`/newDetails?sign=${sign}`} key={index}><div className='item'>
                            <LazyImg container={container} src={pictureImage} />
                            <div>
                                <p className='date'>{createTime}</p>
                                <p className='text'>{title}</p>
                            </div>
                        </div>
                        </Link>
                    }
                })
            }
        </Header>
        {contentData.length > 2 ? <Content>
            {contentData.map((item, index) => {
                if (index > 1) {
                    return <Item {...item} key={index} />
                }
            }
            )}
        </Content>: null}
            <Pagination
                defaultCurrent={1}
                total={total}
                pageSize={pageSize}
                onChange={handleChange}
            />
    </Main>
}