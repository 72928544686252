import React from 'react'
import { Button, Form, Input } from 'antd';
import emptyImg from 'assets/images/certificate/empty.png';
import bgImg from 'assets/images/certificate/bg.png';
import yingzImg from 'assets/images/certificate/yingz.png';
import avImg from 'assets/images/certificate/av.png';

export default (props) => {
  const { result, loaling } = props

  return (
    <div className='certificate_result'>
      <h4>查询 <span>{props.credentialsNum}</span> 证书</h4>
      {
        props.result && !loaling ? (
          <div className='box'>
            <img className='bg' src={bgImg} />
            <img className='yz' src={yingzImg} />
            <img className='av' src={result.avatar || avImg} />
            <div className='txt'>
              于{result.trainingStartDate}至{result.trainingEndDate}
              在{result.trainingInstitutions}参加
              {result.trainingTopics}“{result.certificateTypeName}”培训，共{result.trainingDuration}课时，完成培训计划，考核合格，准予结业。
            </div>
            <div className='time'>{result.issueDate}</div>
            <div className='credentialsNum '>{result.credentialsNum}</div>
            <div className='name'><span>{result.name}</span>同志</div>
          </div>
        )
          : (
            <div className='certificate_empty'>
              <img src={emptyImg} />
              <p>没有找到对应证书</p>
              <Button onClick={() => { props.history.push('/certificate') }}>返回重新查询</Button>
            </div>
          )
      }
    </div>
  )
}