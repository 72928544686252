import React from 'react'
import styled from 'styled-components'
import nextBtn from 'assets/images/index/next.png'
import nextBtnHover from 'assets/images/index/next-hover.png'

export const Main = styled.div`
    width: 1260px;
    margin: 0 auto;
    padding-top: 89px;
    padding-bottom: 140px;
    &>.title{
        padding-bottom: 20px;
        p:nth-of-type(1){
            color: #EBEBEB;
        }
    }
    .swiper-container{
        padding: 0 30px 30px 30px;
    }
    .swiper-wrapper{
        .swiper-slide{
            display: flex;  
            justify-content:space-between;
            &>div{
                box-shadow: 0px 14px 20px 0px rgba(9, 9, 9, 0.08);
                box-sizing: border-box;
            }
        }
        .img{
            width: 365px;
            height: 248px;
            overflow: hidden;
            img{
                display: block;
                width: 384px;
            }
        }
        .text{
            width: 365px;
            height: 245px;
            padding: 0 20px;
            box-sizing: border-box;
            padding-top: 160px;
            text-align: center;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 24px;
        }
    }
    
`
export const Content = styled.div`
    .swiper-pagination,
    .swiper-button-next,
    .swiper-button-prev{
        display: none !important;
    }
`
export const ControlBox = styled.div`
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 30px;
`
export const PreBtn = styled.span`
    display: inline-block;
    width: 33px;
    height: 33px;
    background: url(${nextBtn}) no-repeat;
    background-size: 100%;
    transform: rotate(180deg);
    cursor: pointer;
    margin-right: 17px;
    &:hover{
        background: url(${nextBtnHover}) no-repeat;
        background-size: 100%;
    }
    `
    export const NextBtn = styled.span`
    display: inline-block;
    width: 33px;
    height: 33px;
    background: url(${nextBtn}) no-repeat;
    background-size: 100%;
    cursor: pointer;
    margin-left: 17px;
    &:hover{
        background: url(${nextBtnHover}) no-repeat;
        background-size: 100%;
    }
`
export const MyPagination = styled.ul`
    display: flex;
    &>li{
        width: 10px;
        height: 10px;
        background: #CCCCCC;
        border-radius: 50%;
        cursor: pointer;
    }
    &>li.current{
        background: #333;
    }
    &>li:not(:nth-last-child(1)){
        margin-right: 29px; 
    }
`