import React from 'react'
import styled from 'styled-components'
import icon from 'assets/images/nav/qr_code.png'

export const Container = styled.div`
    position: sticky;
    top: 0;
    z-index: 999;
    background: #fff;
    box-shadow: 0 5px 15px rgba(0,0,0,.08);        
`
export const Main = styled.div`
    width: 1200px;
    height: 100px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    a{
        color: #000;
        text-decoration: none;
    }
`
export const Logo = styled.div`
    width: 218px;
    display: flex;
    align-items: center;
    img{
        width: 100%;
    }
`
export const Nav = styled.ul`
    padding: 0;
    list-style: none;
    display: flex;
    li{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        display: flex;
        align-items: center;
        justify-content: center;
        span{
            cursor: pointer;    
        }
        span.current{
            color: #28BCAC;    
        }
        span:hover{
            color: #28BCAC;   
        }
    }
    li:not(:nth-last-child(1)){
        margin-right: 40px;
    }
    li.learn{
        a{
            color: #FF8533;
        }
        div.icon{
            margin-top: 4px;
            display: inline-block;
            width: 20px;
            height: 20px;
            background: url(${icon}) no-repeat;
            background-size: 100%;
            margin-left: 10px;
            cursor: pointer;
            position: relative;
            &:hover div{
                opacity: 1;
            }
            div{
                z-index: 999;
                width: 130px;
                height: 130px;
                position: absolute;
                right: 50%;
                top: 20px;
                opacity: 0;
                transition: all ease .3s;
                background-color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0px 10px 20px 0px rgba(9, 9, 9, 0.1);
                border-radius: 8px;
                img{
                    width: 110px;
                    height: 110px;
                }
            }
        }
    }
`