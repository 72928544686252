/**
 * 工具函数
 */

/**
 * 数据混合函数
 * @param {*} template 传入的模板
 * @param {Object} source 传入的数据源
 * @example:
 * const template = [{ key: 'name', label: '名字' }, {key: 'age', label: '年龄'}]
 * const source = { name: '张三', age: 12 }
 *
 * const data = dataMixin(template, source);
 * data: [ {label: '名字', value: '张三', key: 'name'} , ... ]
 */
export function dataMixin(template, source) {
  return template.map((item) => {
    const { defaultValue, format, key, ...otherParams } = item;
    const value = source[key];

    const currentValue =
      value === null || value === undefined ? defaultValue : value;
    return {
      ...otherParams,
      key,
      value: format ? format(currentValue) : currentValue,
    };
  });
}

const studentSideUrl = (env) => {
  // 判断是在生产环境还是测试环境，
  const envStudentSideMapping = {
    // 测试
    test: "www.513online.top",
    // 生产
    production: "ke.513.com",
  };
  return envStudentSideMapping[env] || envStudentSideMapping.test;
};

/**
 * 获取学生端地址
 */
export function getStudentSideUrl() {
  return studentSideUrl(process.env.REACT_APP_API_ENV);
}

export function debounce(fn, wait) {
  let timer = null;

  return function (...args) {
    const context = this;

    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, wait);
  };
}

export function throttle(fn, wait) {
  let lastTime = 0;
  return function (...args) {
    const context = this;
    const curTime = Date.now();
    if (curTime - lastTime < wait) return;
    lastTime = curTime;
    fn.apply(context, args);
  };
}

/**
 * 构建环境选择器（注意区别api环境）
 * @param {Record<'development' | 'production', unknown | () => unknown>} config
 * @returns {unknown}
 */
export function buildEnvSelector(config = {}) {
  const env = process.env.NODE_ENV;
  const v = config[env];

  if (typeof v === "function") {
    return v();
  }
  return v;
}

/**
 * 检测数据类型
 * @param {any} value
 * @returns {String} String | Number | Object | Array
 */
export function typeOf(value) {
  return Object.prototype.toString.call(value).slice(8, -1);
}
