import React,{useState} from 'react'
import {Title} from '../../style';
import {Container,Main} from './style';
import LazyImg from 'components/lazyLoad/lazyImg';
 
export default () => {
    let [dom,setDom] = useState(null);
    let data1 = ['1. 在线学习','2.课程实训','3.班级创建','4.学员管理'];
    let data2 = ['5.作业批改','6.线上测试','7.在线点名','8.学习记录'];

    return <Container>
        <Main ref={el=>setDom(el)}>
            <Title className='title'>
                <p>CROSS-BORDER E-COMMERCE</p>
                <p>鲸学-跨境电商线上学习系统</p>
                <p><span>8大</span>学习功能，助力高校课程实训</p>
            </Title>
            <div className='app'>
                <div className='l'>
                    <ul>{data1.map((item,index)=><li key={index}><p>{item}</p><span></span></li>)}</ul>
                    <span></span>
                    <ul>{data2.map((item,index)=><li key={index}><p>{item}</p><span></span></li>)}</ul>
                </div>
                <div>
                    <LazyImg container={dom} src={'assets/img/learn-phone.png'}/>
                </div>
            </div>
            <div className="pc">
                <div className='teacher'>
                    <p>线上学习系统教师端后台界面</p>
                    <LazyImg container={dom} src={'assets/img/learn-1.png'}/>
                </div>
                <div className='student'>
                    <p>线上学习系统课程界面</p>
                    <LazyImg container={dom} src={'assets/img/learn-2.png'}/>
                </div>
            </div>
        </Main>
    </Container>
}
