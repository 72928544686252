import React from 'react'
import {Main} from './style'
import Group from './children/group' //513集团（布局产业-助力教育-带动经济）
import ECommerce from './children/ECommerce' //513全球电商小镇（产学研融合-创造新经济）
 
export default () => {
    return <>
            <Main>
                <p>OUR VISION</p>
                <p>我们的愿景</p>
                <Group/>
            </Main>
            <ECommerce/>
    </>
}
