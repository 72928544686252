import React,{useState} from 'react'
import {Main,Title} from './style'
import LazyImg from 'components/lazyLoad/lazyImg';

let data = [
    {
        name:'江西财经大学',
        path:'1.png'
    },
    {
        name:'新余学院',
        path:'2.png'
    },
    {
        name:'武汉学院',
        path:'3.png'
    },
    {
        name:'湖北民族大学',
        path:'4.png'
    },
    {
        name:'湖南科技学院外国语学院',
        path:'5.png'
    },
    {
        name:'邵阳学院',
        path:'6.png'
    },
    {
        name:'江西师范大学',
        path:'7.png'
    },
    {
        name:'井冈山大学',
        path:'8.png'
    },
    {
        name:'湖南人文科技学院',
        path:'9.png'
    },
    {
        name:'湘南学院外国语学院',
        path:'10.png'
    },
    {
        name:'湖南工学院',
        path:'11.png'
    },
    {
        name:'湖南文理学院',
        path:'12.png'
    },
    {
        name:'湖南女子学院',
        path:'13.png'
    },
    {
        name:'芙蓉学院',
        path:'14.png'
    },
    {
        name:'中南林业科技大学涉外学院',
        path:'15.png'
    },
    {
        name:'江西农业大学南昌商学院',
        path:'16.png'
    },
    {
        name:'九江学院',
        path:'17.png'
    },
    {
        name:'江西理工大学应用科学学院',
        path:'18.png'
    },
    {
        name:'江西理工大学',
        path:'19.png'
    },
    {
        name:'怀化学院',
        path:'20.png'
    },
]

export default () => {
    let [box,setBox] = useState('');
    return <Main>
        <Title>
            <p>COOPERATIVE UNIVERSITY</p>
            <p>合作高校</p>
        </Title>
        <ul ref={el=>setBox(el)}>{
            data.map(({name,path},index)=><li key={index}>
                    <LazyImg title={name} container={box} src={`assets/school-logo/list-5/${path}`}/>
                </li>)
        }</ul>
    </Main>
}