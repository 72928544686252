import React from 'react'
import styled from 'styled-components'

export const Container = styled.div`
    padding: 100px 0 130px 0;
    background-color: #fff;
`
export const Main = styled.div`
    width: 1200px;
    height: 1267px;
    margin: 0 auto;
    .title{
        &>p:nth-of-type(1){
            color: #F2F2F2;
        }
    }
`
export const Content = styled.div`
    padding: 50px 50px 0 0;
    &>div{
        display: flex;
        justify-content: space-between;
        div{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            &:nth-of-type(2){
                p{
                    text-align: center;
                }
            }
        }
        img{
            width: 100%;
            height: auto;
        }
        p{
            width: 100%;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #231815;
            line-height: 20px;
            padding-bottom: 20px;
        }
        .image-2{
            width: 586px;
            position: relative;
        }
        span.text{
            position: absolute;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #231815;
            line-height: 160px;
        }
        span.text-1{
            top: 80px;
            left: 20px;
        }
        span.text-2{
            top: 80px;
            left: 160px;
        }
        span.text-3{
            top: 80px;
            left: 310px;
        }
        span.text-4{
            top: 80px;
            left: 460px;
        }
        span.text-5{
            top: 240px;
            left: 20px;
        }
        span.text-6{
            top: 240px;
            left: 160px;
        }
        span.text-7{
            top: 240px;
            left: 310px;
        }
        span.text-8{
            top: 240px;
            left: 460px;
        }
        .image-shadow{
            img{
                box-shadow: 0px 0px 20px #ccc;
            }
        }
    }
    
`