import React from 'react'
import styled from 'styled-components'
import bg from 'assets/images/index/whoAreWe/bg.png'

export const Bg = styled.div`
    width: 100%;
    min-width: 1200px;
    padding: 60px 0;
    background: url(${bg}) no-repeat;
    background-size: cover;
    background-position: center;
    box-sizing: border-box;
`
export const Main = styled.div`
    width: 1200px;
    height: 100%;
    margin: 0 auto;
`
export const Top = styled.div`
&>p:nth-of-type(1){
    font-size: 58px;
    font-family: Arial;
    font-weight: bold;
    color: #F2F2F2;
    line-height: 36px;
}
&>p:nth-of-type(2){
    font-size: 36px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 36px;
    margin-bottom: 55px;
    transform: translateY(-18px);
}
    &>div{
        display: flex;
        div{
            width: 50%; 
            padding-right: 50px;
            box-sizing: border-box;
            p:nth-of-type(1){
                font-size: 24px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #343434;
                margin-bottom: 25px;
                span{
                    color: #28BCAC;
                }
            }
            p:nth-of-type(2){
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #666666;
                line-height: 36px;
            }
        }
        ul{
            width: 50%; 
            padding-left: 200px;
            display: flex;
            flex-wrap: wrap;
            box-sizing: border-box;
            align-items: center;
            li{
                width: 50%;
                display: flex;
                flex-direction: column;
                span:nth-of-type(1){
                    font-size: 48px;
                    font-family: DINPro;
                    font-weight: 500;
                    color: #28BCAC;
                    line-height: 40px;
                }
                span:nth-of-type(2){
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    line-height: 32px;
                }
            }
        }
    }

`
export const Bottom = styled.div`
    padding-top: 50px;
    width: 1254px;
    margin-left: -27px;
    &>p{ 
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #343434;
        line-height: 50px;
        padding-left: 27px;
    }
    &>div{
        width: 100%;
        height: 340px;
        box-sizing: border-box;
        .swiper-slide{
            width: 25% !important;
            box-sizing: border-box;
            padding: 25px 0;
            &>div{
                width: 250px;
                text-align: center;
                margin: 0 auto;       
                border-radius: 10px; 
                overflow: hidden;               
                box-shadow: 4px 6px 15px 6px rgba(0,0,0,0.1);

                div p{
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FCFCFC;
                    line-height: 22px;  
                    text-align: center;
                }
                div:nth-of-type(1){
                    background-color: #fff;
                    padding: 20px 0;
                    box-sizing: border-box;
                    display: flex;
                    justify-content: center;
                    img{
                        height: 160px;
                    }
                }
                div:nth-of-type(2){
                    height: 120px;
                    background-color: #28BCAC;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }
        }
    }
`