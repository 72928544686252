import React from 'react'
import styled from 'styled-components'

export const Main = styled.div`
    width: 100%;
    min-width: 1200px;  
    margin: 0 auto;
    padding: 60px 0 100px 0;
    background: #F5F5F5;
    &>p{
        text-align: center;
    }
    &>p:nth-of-type(1){
        font-size: 58px;
        font-family: Arial;
        font-weight: bold;
        color: #EAEAEA;
        line-height: 36px;
    }
    &>p:nth-of-type(2){
        font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #343434;
        line-height: 36px;
        transform: translateY(-18px);
    }
`
export const Title = styled.p`
    text-align: center;
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 36px;
`
export const Content = styled.div`
    padding: 39px 30px;
    width: 1200px;
    margin: 0 auto;
    margin-top: 30px;
    box-sizing: border-box;
    display: flex;
    background: #fff;
    &>div{
        width: 50%;
        padding: 0 30px;
    }
    &>div:nth-of-type(1){
        border-right: 1px solid #CCCCCC;
    }
    &>div .flex0{
        display: flex;  
        padding-bottom: 50px;
        border-bottom: 1px solid #CCCCCC;
    }
    &>div .flex0 .leftClass{
        width: 309px;
        display: flex;  
        flex-direction: column;
        justify-content: space-between;
        padding-right: 5px;
        box-sizing-border-box;
    }
    .img{
        width: 200px;
        height: 150px;
        overflow: hidden;
        img{
            width: 100%;
        }
    }
`
export const Name = styled.span`
    display: inline-block;
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    padding: 8px 30px;
    background-color: ${({color})=>color};
    margin-bottom: 40px;
`
export const ItemTitle = styled.p`
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 28px;
`
export const Date = styled.p`
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #28BBAC;
    line-height: 24px;
`
export const Text = styled.p`
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 24px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
`
export const Lists = styled.ul`
    padding: 29px 0;
    li{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 50px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        position: relative;
        padding-left: 15px;
        a{
            color: #999999;
        }
    }
    li::before{
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        content:'';width: 10px;
        width: 10px;
        height: 10px;
        background: #CCCCCC;
        border-radius: 50%;
    }
`