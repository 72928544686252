import React from 'react'
import styled from 'styled-components'
import nextBtn from 'assets/images/index/next.png'
import nextBtnHover from 'assets/images/index/next-hover.png'

export const Main = styled.div`
    max-width: 1670px;
    min-width: 1200px;
    margin: 0 auto;
    position: relative;
    .my-swiper-container{
        margin-top: 0px;
        .swiper-slide-active{
            .mask{
                display: none;
            }
        }
        .swiper-slide{
            position: relative;
            img{
                width: 100%;
            }
        }
        .swiper-slide .swiper-slide-shadow-left,
        .swiper-slide .swiper-slide-shadow-right{
            opacity: 0 !important;
        }   
        .swiper-pagination,
        .swiper-button-next,
        .swiper-button-prev{
            display: none !important;
        }
    }
`

export const Mask = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background-color: rgba(255,255,255,0.8);
`

export const Bottom = styled.div`
    width: 50%;
    height: 70px;
    margin: 0 auto; 
    display: flex;
`
export const CarouselTitle = styled.div`
    width: 50%;
    line-height: 70px;
    p{
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        padding-right: 20px;
        display: -webkit-box;
        overflow: hidden;   
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
    }
`
export const CtrlBox = styled.div`
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`

export const PreBtn = styled.span`
    width: 34px;
    height: 34px;
    margin-right: 15px;
    background: url(${nextBtn}) no-repeat;
    background-size: 100%;
    transform: rotate(180deg);
    cursor: pointer;
    &:hover{
        background: url(${nextBtnHover}) no-repeat;
        background-size: 100%;
    }
    `
    export const NextBtn = styled.span`
    width: 34px;
    height: 34px;
    background: url(${nextBtn}) no-repeat;
    background-size: 100%;
    margin-left: 15px;
    cursor: pointer;
    &:hover{
        background: url(${nextBtnHover}) no-repeat;
        background-size: 100%;
    }
`
export const MyPagination = styled.ul`
    display: flex;
    margin: 0;
    li{
        width: 10px;
        height: 10px;
        background: #CCCCCC;
        border-radius: 50%;
        cursor: pointer;
    }
    li.current{
        background: #333;
    }
    li:not(:nth-last-child(1)){
        margin-right: 17px; 
    }
`