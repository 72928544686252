import React from 'react'
import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    margin: 0 auto;
    min-width: 1200px;
    max-width: 1920px;
    padding: 117px 0 88px 0;
    background: url('assets/img/on-trial-bg.png') no-repeat;
    background-size: auto 100%;
    background-position: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    p{
        font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 36px;
        margin-bottom: 38px;
        letter-spacing: 5px;
    }
    button{
        width: 200px;
        height: 51px;
        border: 1px solid #29BBAC;
        border-radius: 4px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #29BBAC;
        outline: none;
        cursor: pointer;
    }
`