import React from 'react'
import { Title } from '../../style'
import { Main,Content } from './style'
import Item from './item';

let data = [
    [1,2],
    [3,4],
    [5,6,7],
    [8,9],
]

export default () => {
    return <Main>
        <Title>
            <p>COMPANY ENVIRONMENT</p>
            <p>公司环境</p>
        </Title>
        <Content>
            {data.map((item,index)=><Item key={index} item={item}/>)}
        </Content>
    </Main>
}