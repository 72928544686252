import React from 'react'
import { Main, Content } from './style'

let data = [
    {
        className: 'address',
        title: '公司地址',
        text: '深圳市龙岗区岗辉路天安云谷二期4栋2110',
    },
    {
        className: 'phone',
        title: '联系电话',
        text: '0755-21002465',
    },
    {
        className: 'email',
        title: '企业邮箱',
        text: 'service01@513.com',
    },
]

export default () => {
    return <Main>
        <Content>
            <div>
                <p className="en">CONNECT</p>
                <p className="zh">联系我们</p>
                <ul>
                    {
                        data.map((item, index) => {
                            return <li key={index} className={item.className}>
                                <p>{item.title}</p>
                                <p>{item.text}</p>
                            </li>
                        })
                    }
                </ul>
            </div>
        </Content>
    </Main>
}