import React, { useCallback, useEffect, useState } from 'react'
import './index.css'
import Nav from 'components/nav';
import AJAX from "api/api";
import Footer from 'components/footer';

export default (props) => {
    let [contents, setContents] = useState([]);
    let sign = props.location.search.split("?sign=")[1]
    const getByNewsId = useCallback(() => {
        AJAX.Journalism.getByNewsId({ sign: sign }).then(res => {
            setContents(res.data[0])
        })
    }, [])
    useEffect(() => {
        getByNewsId();
    }, [getByNewsId])

    return <>
        <Nav {...props} />
        <div className="contentBox">
        <div>
            <p className="crumbs">
                <span className="bolds">首页 &gt;</span>
                <span className="bolds">资讯分享 &gt;</span>
                <span>正文</span>
            </p>
            <div className="contents">
                <h1>{contents.title}</h1>
                <div className="detailsSum">
                    <span>时间：{contents.createTime}</span>
                    <span>访问人数：{contents.browseCount}</span>
                </div>
                <div className="detailsContent" dangerouslySetInnerHTML={{ __html:contents.contentTwo }}></div>
            </div>
        </div>
        </div>
        <Footer />
    </>
}