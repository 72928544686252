import React from 'react'
import {Title} from '../../style'
import {Part1,Item} from './style'
////513集团（布局产业-助力教育-带动经济
export default () => {

    const data = [
        {
            title:'产业(industry)',
            text:'布局跨境电商、新媒体、直播电商、区块链等新型产业，创建513新型产业基地',
            color:'#FDF0F3'
        },
        {
            title:'教育(education)',
            text:'把产业带进学校，从人才培养到终身学习一站式培养，打造513智能化教育平台',
            color:'#EBFFFD'
        },
        {
            title:'孵化(incubation)',
            text:'提供人才孵化与培养，帮助企业实现产业升级，助力513集团深耕产业生态闭环',
            color:'#F3F4FD'
        },
    ]

    return <Part1>
                <Title>513集团（布局产业-助力教育-带动经济）</Title>
                <ul>
                    {
                        data.map(({title,text,color},index)=>{
                            return <Item key={index} color={color}>
                                <div>
                                    <img src={require(`assets/images/index/ourVision/item-${index+1}.png`)}/>
                                </div>
                                <div>
                                    <p>{title}</p>
                                    <p>{text}</p>
                                </div>
                            </Item>
                        })
                    }
                </ul>
            </Part1>
}
