
import React, { useCallback, useEffect, useState } from 'react'
import AJAX from "api/api";
import { Link } from 'react-router-dom';
import { Main, Title, Content, Name, ItemTitle, Date, Text, Lists } from './style'

export default () => {
    let [leftData, setLeftData] = useState([])
    let [rightData, setRightData] = useState([])

    let curPage = 1
    const getList = useCallback(() => {
        AJAX.Journalism.getAllNewsList({ category: 1, page: 1, size: 5 }).then(res => {
            console.log(res.data, "title")
            let obj = {};
            let lists = [];
            for (let i in res.data.list) {
                if (i == 0) {
                    obj = {
                        name: '集团动态',
                        color: '#FA4D70',
                        title: res.data.list[i].title,
                        date: res.data.list[i].createTime,
                        text: res.data.list[i].content,
                        sign: res.data.list[i].sign,
                        pictureImage: res.data.list[i].pictureImage
                    }
                    console.log(obj, "leftData")
                } else {
                    lists.push({
                        sign: res.data.list[i].sign,
                        text: res.data.list[i].content,
                    })
                }
            }
            obj.list = lists
            console.log(obj, "ovj")
            setLeftData(obj)
        })
    }, [])

    useEffect(() => {
        getList();
    }, [getList])
    const getList1 = useCallback(() => {
        AJAX.Journalism.getAllNewsList({ category: 3, page: 1, size: 5 }).then(res => {
            console.log(res.data, "title")
            let obj = {};
            let lists = [];
            for (let i in res.data.list) {
                if (i == 0) {
                    obj = {
                        name: '渔人计划',
                        color: '#28bbac',
                        title: res.data.list[i].title,
                        date: res.data.list[i].createTime,
                        text: res.data.list[i].content,
                        sign: res.data.list[i].sign,
                        pictureImage: res.data.list[i].pictureImage
                    }
                    console.log(obj, "leftData")
                } else {
                    lists.push({
                        sign: res.data.list[i].sign,
                        text: res.data.list[i].content,
                    })
                }
            }
            obj.list = lists
            setRightData(obj)
        })
    }, [])
    useEffect(() => {
        getList1();
    }, [getList1])
    return <>
        <Main>
            <p>WU YI SAN NEWS</p>
            <p>513资讯</p>
            <Title>国际视野，干货分享</Title>
            <Content>
                <div>
                    <Name color={leftData.color}>{leftData.name}</Name>
                    <Link to={`/newDetails?sign=${leftData.sign}`}>
                        <div className="flex0">
                            <div className="leftClass">
                                <ItemTitle>{leftData.title}</ItemTitle>
                                <Date>{leftData.date}</Date>
                                <Text>{leftData.text}</Text>
                            </div>
                            <div className='img'>
                                <img src={leftData.pictureImage} />
                            </div>
                        </div>
                    </Link>
                    <Lists>
                        {leftData.list ?
                            leftData.list.map((item, index) => {
                                return <li key={index}>
                                    <Link to={`/newDetails?sign=${item.sign}`}>
                                        {item.text}
                                    </Link>
                                </li>
                            })
                            : ''}
                    </Lists>
                </div>
                <div>
                    <Name color={rightData.color}>{rightData.name}</Name>
                    <Link to={`/newDetails?sign=${leftData.sign}`}>
                        <div className="flex0">
                            <div className="leftClass">
                                <ItemTitle>{rightData.title}</ItemTitle>
                                <Date>{rightData.date}</Date>
                                <Text>{rightData.text}</Text>
                            </div>
                            <div className='img'>
                                <img src={rightData.pictureImage} />
                            </div>
                        </div>
                    </Link>

                    <Lists>
                        {rightData.list ?
                            rightData.list.map((item, index) => {
                                return <li key={index}>
                                    <Link to={`/newDetails?sign=${item.sign}`}>
                                        {item.text}
                                    </Link>
                                </li>
                            })
                            : ''}
                    </Lists>
                </div>
            </Content>
        </Main>
    </>
}
