import React from 'react'
import styled from 'styled-components'

export const Main = styled.div`
    width: 1200px;
    margin: 0 auto;
    padding: 90px 0;
    img{
        display: block;
        width: 100%;
    }
`
export const Content = styled.div`
padding-top: 30px;
ul:not(:nth-last-child(1)){
    margin-bottom: 20px;
}
`
export const Container = styled.ul`
display: flex;
justify-content: space-between;

`
export const Item = styled.li`
    div{
        min-height: 216px;
        overflow: hidden;
    }
    div:hover img{
        transform: scale(1.1)
    }
    img{
        width: auto;
        height: 100%;
        transition: all ease .3s;
    }
`