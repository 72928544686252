import React from 'react'
import { Title, Container, Main, List } from './style'
import MySwiper from 'components/swiper'
import { SwiperSlide } from 'swiper/react'
//200+全国高校
export default () => {
    let data = [
        [
            "安阳师范学院外国语学院",
            "广西财经学院",
            "海南热带海洋学院",
            "河池学院",
            "河南城建学院外国语学院",
            "湖北民族学院",
            "湖南人文科技学院",
            "吉首大学",
            "武昌工学院",
            "武汉文理学院",
            "西华师范大学",
            "长江大学文理学院"
        ],
        [
            '深圳职业技术学院',
            '深圳大学',
            '汕头大学',
            '江西财经大学',
            '江西师范大学',
            '华东交通大学理工学院',
            '湖南工学院',
            '四川旅游学院',
            '湖南涉外经济学院',
            '电子科技大学',
            '广西师范大学',
            '广西民族大学',
        ],
        [
            '湖北民族大学',
            '新余学院',
            '赣南师范大学科技学院',
            '南航科技学院',
            '井冈山大学',
            '南昌工学院',
            '南昌师范学院',
            '邵阳学院',
            '怀化学院',
            '湖南科技学院',
            '湘南学校',
            '湖南工学院',
        ],
        [
            '湖南女子学院',
            '中南林业科大涉外学院',
            '九江学院',
            '江西理工应科院',
            '成都东软学院',
            '玉林师范学院',
            '南阳师范大学',
            '北方民族大学',
            '岭南师范学院',
            '南昌工学院',
            '中山大学新华学院',
            '广东新安职业技术学院',
        ]

    ]

    return <Main>
        <Container>
            <Title>
                <p><span>200+</span>全国高校</p>
            </Title>
            <MySwiper
                loop
                autoplay
                navigation
                slidesPerView={1}
                spaceBetween={100}
            >
                {
                    data.map((item, index) => {
                        return <SwiperSlide key={index}>
                            <ul>
                                {
                                    item.map((name, index2) => {
                                        return <List key={index2}>
                                            <div>
                                                <img width="94" height="94" src={`assets/school-logo/list-${index + 1}/${index2 + 1}.png`} />
                                                <p>{name}</p>
                                            </div>
                                        </List>
                                    })
                                }
                            </ul>
                        </SwiperSlide>
                    })
                }
            </MySwiper>
        </Container>
    </Main>
}
