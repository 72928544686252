import React from 'react'
import styled from 'styled-components'

export const Main = styled.div`
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    padding-top: 80px;
    &>p{
        text-align: center;
    }
    &>p:nth-of-type(1){
        font-size: 58px;
        font-family: Arial;
        font-weight: bold;
        color: #F3F3F3;
        line-height: 36px;
    }
    &>p:nth-of-type(2){
        font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #343434;
        line-height: 36px;
        transform: translateY(-18px);
    }
`
export const Title = styled.div`
    padding: 30px 0;
    p{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #28BBAC;
        line-height: 36px;
        span{
            font-size: 46px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #28BBAC;
            line-height: 36px;
        }
    }
    p::before{
        display: inline-block;
        content:'';
        width: 150px;
        height: 2px;
        background-color: #28BBAC;
        margin-right: 15px;
    }
    p::after{
        display: inline-block;
        content:'';
        width: 150px;
        height: 2px;
        background-color: #28BBAC;
        margin-left: 15px;
    }
`