import React from 'react'
import styled from 'styled-components'
import nextBtn from 'assets/images/index/next.png'
import nextBtnHover from 'assets/images/index/next-hover.png'

export const Main = styled.div`
    width: 100%;
    height: 100%;
    padding-top: 30px;
    padding-bottom: 70px;
    background: #F5F5F5;
`
export const Title = styled.div`
    padding: 30px 0;
    p{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #28BBAC;
        line-height: 36px;
        span{
            font-size: 46px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #28BBAC;
            line-height: 36px;
        }
    }
    p::before{
        display: inline-block;
        content:'';
        width: 150px;
        height: 2px;
        background-color: #28BBAC;
        margin-right: 15px;
    }
    p::after{
        display: inline-block;
        content:'';
        width: 150px;
        height: 2px;
        background-color: #28BBAC;
        margin-left: 15px;
    }
`
export const Container = styled.div`
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .my-swiper-container{
        .swiper-container{
            width: 1400px;
            padding: 0 100px 20px 100px;
            box-sizing: border-box;
        }
        .swiper-slide{
            width: 100% !important;
            ul{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }
        }
        .swiper-button-next::after,.swiper-button-prev::after{
            display: none;
        }
        .swiper-button-next,.swiper-button-prev{
            display: none !important;
            width: 60px;
            height: 60px;
        }
        &:hover .swiper-button-next{
            display: block !important;
        }
        &:hover .swiper-button-prev{
            display: block !important;
        }
        
        .swiper-button-next{
            background: url(${nextBtn}) no-repeat;
            background-size: 100%;
        }
        .swiper-button-prev{
            background: url(${nextBtn}) no-repeat;
            background-size: 100%;
            transform: rotate(180deg);
        }
        .swiper-button-next:hover,
        .swiper-button-prev:hover{
            background: url(${nextBtnHover}) no-repeat;
            background-size: 100%;
        }
    }
`

export const List = styled.li`
    width: 25%;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    div{
        background-color: #fff;
        padding: 22px 0 25px 0;
        box-shadow: 0px 14px 20px 0px rgba(9, 9, 9, 0.08);
    }
    p{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 36px;
    }
    img{
        display: inline-block;
    }
`