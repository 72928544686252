import React from 'react'
import styled from 'styled-components'

export const Title = styled.div`
    width: 1200px;
    margin: 0 auto;
    &>p{
        text-align: center;
    }
    &>p:nth-of-type(1){
        font-size: 58px;
        font-family: Arial;
        font-weight: bold;
        color: #F3F3F3;
        line-height: 36px;
    }
    &>p:nth-of-type(2){
        font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #343434;
        line-height: 36px;
        transform: translateY(-18px);
    }
    &>p:nth-of-type(3){
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 26px;
        span{
            color: #28BBAC;
            font-size: 32px;
        }
    }
`