import React from 'react'
import {Title} from '../../style'
import {Container,Mask,Intro} from './style'
//6大新型产业
export default () => {

    let data1 = [
        {
            text1:'跨境电商产业',
            text2:'从0到1打造百万美金企业',
            intro:{
                title:'跨境电商产业',
                content:'伍壹叁教育集团2006年成立至今，一直是国内领先的跨境电商运营企业，公司帮助更多的跨境电商企业和个人提供完善的跨境电商运营解决方案。跨境电商解决方案提供从如何开店、店铺选品、商品竞价排名、仓储物流、人才储备等每个环节的运营方法，帮助企业实现从0到1的过程，让企业迅速盈利。'
            }
        },
        {
            text1:'电竞经营产业',
            text2:'提供电竞教育、投资、生态专业解决方案',
            intro:{
                title:'电竞经营产业',
                content:'以513电竞学院为核心，与合作高校展开电竞专业共建、模式探索与应用，培养高素质、专业化的电竞从业人才梯队。同时依托自有商业实力，联合业内知名游戏品牌、IP运营、文创企业、电竞赛事运营、文化园等电竞产业链上下游企业，创建电竞产业运营、电竞教育、电竞主题产业园、电竞产业协会等电竞生态多元化版块，为电竞行业发展提供持久续航。'
            }
        },
        {
            text1:'直播电商产业',
            text2:'拼多多、抖音官方合作伙伴助力直播变现',
            intro:{
                title:'直播电商产业',
                content:'伍壹叁教育与拼多多、抖音官方战略合作，针对拼多多运营，抖音新媒体运营，伍壹叁帮助MCN公司以及想进入直播行业的个人，拼多多运营解决方案从初期运营、中期发展、后期持续盈利三个方向出发，帮助想进入拼多多的企业和个人完善运营和团队搭建。在新媒体抖音方便，伍壹叁教育集团建立新媒体抖音直播解决方案，从小白到直播大咖的蜕变。'
            }
        },
        {
            text1:'大数据人工智能产业',
            text2:'哈工大底层算法完成企业数据精细化管理',
            intro:{
                title:'大数据人工智能产业',
                content:'伍壹叁教育集团联合哈工大产教融合集团有限公司，利用哈工大完善的底层算法技术，帮助企业和需要数据计算的企业完善内部数据精细化管理，并提出让新型高科技技术走进校园，帮助从高校到企业的全方位解决方案。'
            }
        },
        {
            text1:'网络安全攻防产业',
            text2:'为金融、制造、航天、互联网等领域量身打造',
            intro:{
                title:'网络安全攻防产业',
                content:'伍壹叁教育集团与四叶草网络安全有限公司深度战略合作，布局网络安全（攻防渗透）领域，针对金融、制造、航天、互联网等领域提出完善的行业解决方案，并且我们开放专业的网络安全模拟平台，让企业、高校能在模拟平台上看到如何修复漏洞和防护企业。'
            }
        },
        {
            text1:'区块链智能合约产业',
            text2:'区块链合约技术解决企业数据保护与完善',
            intro:{
                title:'区块链智能合约产业',
                content:'伍壹叁教育集团与零时科技深度战略合作，在区块链智能合约领域高度合作，与时俱进运用区块链技术帮助企业解决从数据的保护到数据的完善的每一步解决方案。'
            }
        },
    ]

    return <Container>
            <Title>
                <p><span>6</span>大新型产业</p>
            </Title>
            <ul>
                {
                    data1.map(({text1,text2,intro},index)=>{
                        return <li key={index}>
                            <div>
                                <img src={require(`assets/images/index/ourService/service1-${index+1}.png`)}/>
                                <Mask></Mask>
                                {intro && <Intro className='intro'>
                                    <p>{intro.title}</p>    
                                    <p>{intro.content}</p>    
                                </Intro>}
                            </div>
                            <div>
                                <span>{`0${index+1}`}</span>
                                <p>
                                    <span>{text1}</span>
                                    <span>{text2}</span>
                                </p>
                            </div>
                        </li>
                    })
                }
            </ul>
        </Container>
}
