import React from 'react'
import styled from 'styled-components'

export const Main = styled.div`
    max-width: 1920px;
    min-width: 1200px;
    margin: 0 auto;
    padding: 90px 0 0 0;
`
export const Title = styled.div`
    padding-bottom: 50px;
    text-align: center;
    p:nth-of-type(1){
        font-size: 58px;
        line-height: 58px;
        font-family: Arial;
        font-weight: bold;
        color: #F3F3F3;
    }
    p:nth-of-type(2){
        font-size: 36px;
        line-height: 36px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #343434;
        transform: translateY(-30px);
    }
`
export const Part1 = styled.div`
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    p.title{
        font-size: 100px;
        font-family: DIN;
        font-weight: 400;
        line-height: 122px;
        color: rgba(230,230,230,1);
        position: relative;
        span{
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 33px;
            color: #333333;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
    }
    p{
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 30px;
        color: #999999;
    }
    .content{
        width: 424px;
    }
    .img{
        width: 670px;
        height: 396px;
        padding-top: 50px;
    }
`
export const Part2 = styled.div`
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: 140px;
    .content{
        width: 395px;
    }
    .img{
        width: 684px;
        height: 456px;
    }
    p.title{
        font-size: 100px;
        font-family: DIN;
        font-weight: 400;
        line-height: 122px;
        color: rgba(230,230,230,1);
        position: relative;
        span{
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 33px;
            color: #333333;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            i{
                font-style: normal;
                color: #3CC1B4;
                font-size: 36px;
            }
        }
    }
    p{
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 30px;
        color: #999999;
    }
`

export const Part3 = styled.div`
    max-width: 1920px;
    min-width: 1200px;
    margin: 0 auto;
    background: #F5F5F5;
    margin-top: 108px;
    padding: 70px 0 69px 0;
    .main{
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        .content{
            width: 395px;
            padding-top: 70px;
        }
        .img{
            width: 538px;
            height: 520px;
        }
        p.title{
            font-size: 100px;
            font-family: DIN;
            font-weight: 400;
            line-height: 122px;
            color: rgba(230,230,230,1);
            position: relative;
            span{
                font-size: 24px;
                font-family: PingFang SC;
                font-weight: 400;
                line-height: 33px;
                color: #333333;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                i{
                    font-style: normal;
                    color: #F38E68;
                    font-size: 36px;
                }
            }
        }
        p{
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 30px;
            color: #999999;
        }
    }
`

export const Part4 = styled.div`
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-top: 112px;
    .content{
        width: 395px;
        padding-top: 70px;
    }
    .img{
        width: 538px;
        height: 460px;
    }
    p.title{
        font-size: 100px;
        font-family: DIN;
        font-weight: 400;
        line-height: 122px;
        color: rgba(230,230,230,1);
        position: relative;
        span{
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 33px;
            color: #333333;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            i{
                font-style: normal;
                color: #59B0F2;
                font-size: 36px;
            }
        }
    }
    p{
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 30px;
        color: #999999;
    }
`