import React,{useState} from 'react'
import { Main,Tabs } from './style'
import {Title} from '../../style'
import News from '../news'
import Plan from '../plan'

export default () => {
    let [type,setType] = useState(0);

    return <Main>
        <Title>
            <p>INFORMATION CENTER</p>
            <p>资讯中心</p>
        </Title>
        <Tabs>
            <p className={type === 0 ? 'current' : ''} onClick={()=>{setType(0)}}>集团动态</p>
            <span></span>
            <p className={type === 1 ? 'current' : ''} onClick={()=>{setType(1)}}>渔人计划</p>
        </Tabs>
        {
            type ? <Plan/> : <News/>
        }
    </Main>
}