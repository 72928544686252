import React from 'react'
import styled from 'styled-components'

export const Container = styled.div`
    padding: 85px 0 63px 0;
    background-color: #fff;
`
export const Main = styled.div`
    width: 1200px;
    margin: 0 auto;
`
export const List = styled.ul`
    display: flex;
    justify-content: center;
    padding: 60px 0 90px 0;
    position: relative;
    &::after{
        content:'';
        position: absolute;
        bottom: 45px;
        width: 1080px;
        height: 2px;
        background: #E6E6E6;
        border-radius: 1px;
    }
`
export const Item = styled.li`
    width: 123px;
    height: 63px;
    box-shadow: 0px 3px 12px 0px rgba(62, 62, 62, 0.28);
    border-radius: 8px;
    position: relative;
    p{
        padding: 20px 0 0 20px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
    }
    span{
        position: absolute;
        bottom: -50px;
        left: 50%;
        transform: translateX(-50%);
        width: 13px;
        height: 13px;
        background: #FFFFFF;
        border: 1px solid #556E81;
        border-radius: 50%;
        z-index: 2;
    }
    span::before{
        content:'';
        position: absolute;
        transform: translate(2px,2px);
        width: 7px;
        height: 7px;
        background: ${({color})=>color};
        border-radius: 50%;
    }
    &:not(:nth-last-child(1)){
        margin-right:15px;
    }
    &::before{
        content:'';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -45px;
        width: 2px;
        height: 45px;
        background-color: #E6E6E6;
    }
    &:nth-last-child(1)::after{
        content:'';
        position: absolute;
        right: 5px;
        transform: translateX(-50%) rotate(-45deg);
        bottom: -46px;
        width: 2px;
        height: 10px;
        background-color: #E6E6E6;
    }
    &:nth-last-child(2)::after{
        content:'';
        position: absolute;
        right: -133px;
        transform: translateX(-50%) rotate(-135deg);
        bottom: -52px;
        width: 2px;
        height: 10px;
        background-color: #E6E6E6;
    }
`
export const Images = styled.div`
    height: 600px;
    div{
        position: absolute;
        p{
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #231815;
            line-height: 20px;
            padding-bottom: 18px;
        }
    }
    .img-1{
        transform: translateY(162px);
    }
    .img-2{
        transform: translate(332px,53px);
        img{
            box-shadow: 0px 0px 20px #ccc;
        }
    }
    .img-3{
        transform: translateX(657px);
        img{
            box-shadow: 0px 0px 20px #ccc;
        }
    }
`