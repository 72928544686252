import React from 'react'
import styled from 'styled-components'

export const Container = styled.div`
    img{
        display: block;
    }
    ul{
        display:flex;
        flex-wrap: wrap;
        li{
            width: 50%;
            cursor: pointer;
            &>div:nth-of-type(1){
                position: relative;
            }
            &>div:nth-of-type(2){
                padding: 20px 0 40px 0;
                display:flex;
                &>span{
                    width: 100px;
                    font-size: 70px;
                    font-family: DINPro;
                    font-weight: 300;
                    font-style: italic;
                    color: #CCCCCC;
                    line-height: 70px;
                }
                p{
                    display:flex;
                    flex-direction: column;
                    padding-top: 5px;
                    span{
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        line-height: 30px;
                    }
                    span:nth-of-type(1){
                        color: #333333;
                        font-size: 20px;
                    }                
                    span:nth-of-type(2){
                        font-size: 16px;
                        color: #666666;
                    }                
                }
            }
        }
        li:hover div:nth-of-type(1) div:nth-of-type(1){
            opacity: 0;
        }
        li:hover div:nth-of-type(1) div:nth-of-type(2){
            opacity: 1;
        }
    }
`
export const Mask = styled.div`
    opacity: 1;
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(12, 32, 46, 0.2);
    transition: all ease 0.3s;  
`
export const Intro = styled.div`
    opacity: 0;
    position: absolute;
    top: 0px;
    height: 100%;
    color: #fff;
    padding: 45px 80px 0 80px;
    box-sizing: border-box;
    background: rgba(12, 32, 46, 0.7);
    transition: all ease 0.3s;  
    p:nth-of-type(1){
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 60px;
    }
    p:nth-of-type(2){
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 26px;
        opacity: 0.8;
    }
`