import React,{useState} from 'react'
import {Container} from './style';
import {Modal} from 'antd';
 
export default () => {
    let [visible,setVisible] = useState(false);
    let showModal = () => {
        setVisible(true);
    }
    let handleCancel = () => {
        setVisible(false);
    }

    return <Container>
        <p>一键解决高校实训问题</p>
        <button onClick={showModal}>免费试用</button>
        <Modal
            width={400}
            className="we-chat-modal"
          title=""
          visible={visible}
          onCancel={handleCancel}
          footer={false}
        >
            <img width="236" height="236" src='assets/img/we-chat.png'/>
            <p>扫一扫上面二维码图案，加我微信</p>
        </Modal>
    </Container>
}
