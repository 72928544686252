import React from 'react'
import { Title } from '../../style'
import { Main} from './style'
import GovernmentVisitCarousel from 'components/governmentVisitCarousel'

export default () => {
    return <Main>
        <Title>
            <p>GOVERNMENT VISIT</p>
            <p>政府来访</p>
        </Title>
        <GovernmentVisitCarousel/>
    </Main>
}
