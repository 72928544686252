import React,{useState} from 'react'
import {Title} from '../../style';
import {Container,Main,List,Item,Images} from './style';
import LazyImg from 'components/lazyLoad/lazyImg';
 
export default () => {
    let [dom,setDom] = useState(null);
    let data = [
        {
            text:'1. 用户登录',
            color:'#28BBAC',
            fontColr:{
                color:'#fff'
            }
        },
        {
            text:'2. 产品上传',
            color:'#556E81',
            fontColr:{
                color:'#fff'
            }
        },
        {
            text:'3. listing撰写',
            color:'#FFE400',
            fontColr:{
                color:'#000'
            }
        },
        {
            text:'4. CPC广告',
            color:'#28BBAC',
            fontColr:{
                color:'#fff'
            }
        },
        {
            text:'5. 邮件回复',
            color:'#556E81',
            fontColr:{
                color:'#fff'
            }
        },
        {
            text:'6. 库存管理',
            color:'#FFE400',
            fontColr:{
                color:'#000'
            }
        },
        {
            text:'7. FBA发货',
            color:'#28BBAC',
            fontColr:{
                color:'#fff'
            }
        },
        {
            text:'8. 数据分析',
            color:'#556E81',
            fontColr:{
                color:'#fff'
            }
        },
    ];

    return <Container>
        <Main ref={el=>setDom(el)}>
            <Title>
                <p>SIMULATION</p>
                <p>鲸练-跨境电商模拟实操系统</p>
                <p><span>1:1</span>仿真平台，助力高校课程实操</p>
            </Title>
            <List >{
                data.map((item,index)=><Item key={index} color={item.color} style={{backgroundColor:item.color}}>
                    <p style={item.fontColr}>{item.text}</p>
                    <span></span>
                </Item>)
            }</List>
            <Images>
                <div className='img-1'>
                    <p>亚马逊后台登录界面</p>
                    <LazyImg container={dom} src={'assets/img/practise-1.png'}/>
                </div>
                <div className='img-2'>
                    <p>亚马逊卖家后台界面</p>
                    <LazyImg container={dom} src={'assets/img/practise-2.png'}/>
                </div>
                <div className='img-3'>
                    <p>亚马逊卖家库存管理界面</p>
                    <LazyImg container={dom} src={'assets/img/practise-3.png'}/>
                </div>
            </Images>
        </Main>
    </Container>
}
