import React from 'react'
import Nav from 'components/nav' 
import Footer from 'components/footer'
import Banner from './children/banner' 
import GovernmentVisit from './children/governmentVisit' 
import Enterprise from './children/enterprise' 
import Visit from './children/visit' 

export default (props) => {
    console.log(props,"communication")
    return <>
        <Nav {...props}/>
        <Banner/>
        <GovernmentVisit/>
        <Enterprise/>
        <Visit/>
        <Footer/>
    </>
}