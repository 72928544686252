import React from 'react'
import {Title} from './style'
import NewEnterprise from './children/newEnterprise'//60+新型企业
import Universities from './children/universities'//200+全国高校
import Photo from './children/photo'//30+政企校合影

export default () => {
    return <>
            <Title>
                <p>OUR COPERATION</p>
                <p>我们的合作</p>
            </Title>
            <NewEnterprise/>
            <Universities/>
            <Photo/>
    </>
}
