import React,{useState} from 'react'
import {Main,Title,Part1,Part2,Part3,Part4} from './style'
import LazyImg from 'components/lazyLoad/lazyImg';

export default () => {
    let [part1,setPart1] = useState('');
    let [part2,setPart2] = useState('');
    let [part3,setPart3] = useState('');
    let [part4,setPart4] = useState('');
    return <Main>
        <Title>
            <p>513 EDUCATION GROUP</p>
            <p>513教育集团</p>
        </Title>
        <Part1 ref={el=>setPart1(el)}>
            <div className='content'>
                <p className='title'>01<span>助力中国企业培养稀缺人才</span></p>
                <p>513集团成立于2006年，专注于为中国企业培养稀缺人才，提供人才培养、猎聘、企业培训、咨询、孵化、联合运营、供应链等一站式综合服务。2020年，513集团再度升级，携手哈工产教融合、四叶草安全、零时科技、香蕉智能等高科技企业巨头企业，在大数据、网络安全、区块链技术、AI人工智能、直播电商领域高度合作，共建开放、共享、智慧的513教育生态圈。</p>
            </div>
            <div className='img'>
                <LazyImg container={part1} src={'assets/combine/eduGroup-img-1.png'}/>
            </div>
        </Part1>
        <Part2 ref={el=>setPart2(el)}>
            <div className='img'>
                <LazyImg container={part2}  src={'assets/combine/eduGroup-img-2.png'}/>
            </div>
            <div className='content'>
                <p className='title'>02<span>融<i>人才</i> - 513渔人计划</span></p>
                <p>513渔人计划致力于帮助全国高校培养跨境电商人才，制订“培训+就业+服务”的一站式人才培养计划，助力高校人才技能培训与就业。目前，渔人计划已为全国200+高校培养跨境电商人才20000+。</p>
            </div>
        </Part2>
        <Part3 ref={el=>setPart3(el)}>
            <div className='main'>
                <div className='content'>
                    <p className='title'>03<span>融<i>产业</i> - 513电商实训基地</span></p>
                    <p>513电商实训基地致力于联合全国高校共建校园实训基地，帮助全国跨境电商综合试验区城市开展国内外电商技能培训、人才培养、中小微企业孵化、电商产业服务、供应链产品对接等项目。</p>
                </div>
                <div className='img'>
                    <LazyImg container={part3}  src={'assets/combine/eduGroup-img-3.png'}/>
                </div>
            </div>
        </Part3>
        <Part4 ref={el=>setPart4(el)}>
            <div className='img'>
                <LazyImg container={part4} src={'assets/combine/eduGroup-img-4.png'}/>
            </div>
            <div className='content'>
                <p className='title'>04<span>融<i>创新</i> - 513云课堂</span></p>
                <p>513云课堂是513教育集团旗下智能化在线学习平台，通过“线上学习+就业服务+大数据匹配+智能服务”的运营模式为全国高校提供线上课程学习、实训、就业、成长等服务，打造一站式线上学习就业平台。</p>
            </div>
        </Part4>
    </Main>
}