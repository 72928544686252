import React from 'react'
import styled from 'styled-components'

export const Main = styled.div`
    width: 100%;
    height: 420px;
    background: #050818;
    opacity: 0.9;
`
export const Part1 = styled.div`
    width: 1200px;
    margin: 0 auto;
    padding: 70px 0;
    display: flex;
    justify-content: space-between;
    &>div:nth-of-type(1){
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &>div:nth-of-type(2){
        display: flex;
        &>div:nth-of-type(1){
            margin-right: 29px;
        }
        p{
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 50px;
            opacity: 0.6;
            text-align: center;
        }
    }
`
export const NavLink = styled.ul`
    display: flex;
    li{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 30px;
        a{
            color: #FFFFFF;
        }
    }
    li:not(:nth-last-child(1)){
        margin-right: 60px;
    }
`
export const PhoneNumber = styled.p`
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 30px;
    margin-top: 40px;
    span{
        font-size: 28px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #28BBAC;
        line-height: 30px;  
    }
`
export const Links = styled.ul`
    display: flex;
    li{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 30px;
        opacity: 0.6;
        a{
            color: #FFFFFF;
        }
    }
    li:nth-of-type(1){
        opacity: 1;
        width: 88px;
        height: 30px;
        background: #28BBAC;
        border-radius: 4px;
        text-align: center;
    }
    li:not(:nth-last-child(1)){
        margin-right: 30px;
    }

`

export const Part2 = styled.div`
    width: 100%;
    border-top: 1px solid #CCCCCC;
    opacity: 0.32;
`
export const Content = styled.div`
    width: 1200px;
    height: 86px;
    margin: 0 auto; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    p{
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 30px;
        opacity: 0.6;
        &>a{
            color: #FFFFFF;
            line-height: 30px;
            opacity: 0.6;
        }
    }
    
    
`