import React from 'react'
// import './index.css'
import Nav from 'components/nav' 
import Footer from 'components/footer'
import Carousel from './carousel' //轮播
import WhoAreWe from './whoAreWe' //我们是谁
import OurService from './ourService' //我们的服务
import OurCoperation from './ourCoperation' //我们的合作
import OurVision from './ourVision' //我们的愿景
import Development from './development' //513发展历程   
import Information from './information' //513资讯

export default (props) => {
    return <>
        <Nav {...props}/>
        <Carousel/>
        <WhoAreWe/>
        <OurService/>
        <OurCoperation/>
        <OurVision/>
        <Development/>
        <Information/>
        <Footer/>
    </>
}