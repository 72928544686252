import React from 'react'
import { Link } from 'react-router-dom'
import { Main, Part1, NavLink, PhoneNumber, Links, Part2, Content } from './style'

export default () => {
    let nav = [
        {
            text: '政企学交流',
            path: '/communication'
        },
        {
            text: '产教融合',
            path: '/combine'
        },
        // {
        //     text:'合作伙伴'
        // },
        {
            text: '资讯分享',
            path: '/companyInfo'
        },
        {
            text: '联系我们',
            path: '/aboutUs'
        },
        // {
        //     text:'线上学习平台',
        //     // path:'/aboutUs'
        // },
    ]

    let links = [
        {
            text: '互联工场',
            link: 'http://www.iwo.com/'
        },
        {
            text: '62优名网',
            link: 'https://www.62.com/'
        },
        {
            text: '智能香蕉',
            link: 'http://www.banana.ai/'
        },
        {
            text: '智汇创想',
            link: 'http://www.zhcxkj.com/'
        },
    ]

    return <Main>
        <Part1>
            <div>
                <NavLink>
                    {
                        nav.map((item, index) => {
                            return <li key={index}><Link to={item.path}>{item.text}</Link></li>
                        })
                    }
                    <li><a target='_blank' href='https://ke.513.com/'>线上学习平台</a></li>
                </NavLink>
                <PhoneNumber>联系热线：<span>0755-21002465</span></PhoneNumber>
                <Links>
                    <li>友情链接</li>
                    {
                        links.map((item, index) => {
                            return <li key={index}><a target='_blank' href={item.link}>{item.text}</a></li>
                        })
                    }
                </Links>
            </div>
            <div>
                <div>
                    <img src={require('assets/images/footer/qr_code@2x.png')} />
                    <p>官方微信订阅号</p>
                </div>
                <div>
                    <img src={require('assets/images/footer/qr_code_app@2x.png')} />
                    <p>513云课堂APP</p>
                </div>
            </div>
        </Part1>
        <Part2>
            <Content>
                <p>Copyright © 2006-2022 深圳伍壹叁电商集团有限公司 版权所有</p>
                <p>
                    <a
                        href='https://beian.miit.gov.cn/'
                        target='_blank'
                    >
                        {window.location.href.includes('https://513.com/') ? '粤ICP备19126219号' : '粤ICP备19058570号'}
                    </a> |网站地图 |免责声明</p>
            </Content>
        </Part2>
    </Main>
}