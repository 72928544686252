import React from 'react'
import styled from 'styled-components'

export const Main = styled.div`
    max-width: 1670px;
    min-width: 1200px;
    margin: 0 auto;
    position: relative;
    padding: 80px 0 100px 0;
    .my-swiper-container{
        margin-top: 40px;
        .swiper-slide-active{
            .mask{
                display: none;
            }
        }
        .swiper-slide{
            position: relative;
            img{
                width: 100%;
            }
        }
        .swiper-slide .swiper-slide-shadow-left,
        .swiper-slide .swiper-slide-shadow-right{
            opacity: 0 !important;
        }   
        .swiper-pagination,
        .swiper-button-next,
        .swiper-button-prev{
            display: none !important;
        }
    }
`