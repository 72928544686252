/**
 * 此文件作为闭包，共享一些全局状态，刷新就没有的
 */

export const historyState = (() => {
  let history = null;

  return {
    get() {
      return history;
    },
    set(h) {
      history = h;
    },
  };
})();
