import React from 'react'
import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    background: #F5F5F5;
    padding: 90px 0 90px 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
`
export const Main = styled.div`
    width: 1920px;
    margin: 0 auto;
    background: #F5F5F5;
    &>.title{
        padding-bottom: 15px;
        p:nth-of-type(1){
            color: #EBEBEB;
        }
    }
    
`
export const Content = styled.div`
    ul{
        display: flex;
        justify-content: center;
        padding: 20px 0;
        li{
            width: 180px;
            height: 90px;
            margin: 0 15px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0 5px 20px rgba(0,0,0,.1);        
            img{
                width: 180px;
            }
        }
    }
    
`
