class MyIntersectionObserver{
    constructor(){
        this.listeners = [];
        this.options = {
            root: null,
            rootMargin: '0px',
            thresholds: 1,
        };
        this.io = this.init();
    }

    init = () => {
        if(window.IntersectionObserver){
            return new IntersectionObserver(this.callback,this.options);
        }
        return false;
    }

    observe = (dom,callback) => {
        for(let i = 0; i < this.listeners.length; i++){
            if(this.listeners[i].dom === dom){              
                this.listeners[i].callbacks.push(callback);
                return;
            }
        }
        this.io.observe(dom);
        this.listeners.push({
            dom,
            callbacks:[callback]
        });
    }

    unobserve = (dom) => {
        this.listeners = this.listeners.filter(item=>{
            if(item.dom === dom){
                this.io.unobserve(item.dom);
                return false;
            }
            return true;
        });
    }

    callback = e => {
        for(let i = 0; i < e.length; i++){
            let {target,isIntersecting} = e[i];
            if(!isIntersecting) continue;
            this.listeners = this.listeners.filter(({dom,callbacks})=>{
                if(dom !== target)  return true;
                for(let j = 0; j < callbacks.length; j++){
                    callbacks[j]();
                }
                return false;
            })
        }
    }
}
export default new MyIntersectionObserver();