import React from 'react'
import {Container} from './style'
import { SwiperSlide } from 'swiper/react';
import MySwiper from 'components/swiper'

export default () => {
  return <Container>
      <MySwiper
        loop
        autoplay
        navigation
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        <SwiperSlide>
          <img src={require('assets/images/index/banner-1.png')}/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={require('assets/images/index/banner-2.png')}/>
        </SwiperSlide>
    </MySwiper>
  </Container>
};