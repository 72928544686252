import React,{useState} from 'react'
import {Main} from './style';
import LazyImg from 'components/lazyLoad/lazyImg';
 
export default () => {
    let [dom,setDom] = useState(null);
    return <Main ref={el=>setDom(el)}>
        <LazyImg container={dom} src={'assets/banner/banner.png'}/>
    </Main>
}
