import React from 'react'
import styled from 'styled-components'

export const Container = styled.div`
    padding: 85px 0 96px 0;
    background-color: #F5F5F5;
`
export const Main = styled.div`
    width: 1200px;
    margin: 0 auto;
    .title{
        &>p:nth-of-type(1){
            color: #EDEDED;
        }
    }
    .app{
        padding: 40px 0 0 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img{
            height: 498px;
        }
        .l{
            height: 152px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding-left: 50px;
            &>span{
                display: inline-block;
                width: 513px;
                height: 1px;
                background: #28BBAC;
                position: relative;
            }
            &>span::before{
                position: absolute;
                left: 0;
                top: 0px;
                content: '';
                width: 1px;
                height: 33px;
                background: #28BBAC;
            }
            &>span::after{
                position: absolute;
                right: 0;
                top: -33px;
                content: '';
                display: inline-block;
                width: 1px;
                height: 33px;
                background: #28BBAC;
            }
            ul{
                display: flex;
                li{
                    width: 130px;
                    height: 44px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #231815;
                    border: 1px solid #28BBAC;
                    border-radius: 22px;
                    text-align: center;
                    line-height: 44px;
                    position: relative;
                }
                li:not(:nth-of-type(4)){
                    margin-right: 40px;
                    span{
                        position: absolute;
                        right: -85px;
                        top: -50px;
                        display: inline-block;
                        width: 40px;
                        height: 1px;
                        background-color: #28BBAC;
                        position: relative;
                    }
                    span::before{
                        position: absolute;
                        right: 0;
                        top: -3px;
                        content: '';
                        width: 6px;
                        height: 1px;
                        background-color: #28BBAC;
                        transform: rotate(45deg);
                    }
                    span::after{
                        position: absolute;
                        right: 0;
                        top: 3px;
                        content: '';
                        width: 6px;
                        height: 1px;
                        background-color: #28BBAC;
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }
    .pc{
        padding-top: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .teacher{
            width: 400px;
            height: 400px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            p{
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #231815;
                line-height: 20px;
                margin-bottom: 23px;
            }
            img{
                width: 100%;
            }
        }
        .student{
            width: 682px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            p{
                width: 100%;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #231815;
                line-height: 20px;
                margin-bottom: 23px;
            }
            img{
                width: 100%;
                box-shadow: 0px 0px 20px #ccc;
            }
        }
    }
`