import React from 'react'
import styled from 'styled-components'

export const Container = styled.div`
    width: 1200px;
    margin: 0 auto;
    padding: 120px 0;
`
export const Main = styled.div`
    width: 1200px;
    margin: 0 auto;
    position: relative;
    &>div:nth-of-type(1){
        margin-bottom: 100px;
    }
    &>div{
        display: flex;
        justify-content: space-between;
        &>div{
            width: 394px;
            height: 196px;
            padding: 37px 29px 37px 140px;
            box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            box-sizing: border-box;
            position: relative;
            img{
                width: 90px;
                height: 90px;
                position: absolute;
                top: 50%;
                left: 30px;
                transform: translateY(-50%);
            }
            .title{
                font-size: 22px;
                font-family: PingFang SC;
                font-weight: bold;
                line-height: 30px;
                color: #333333;
                padding-bottom: 15px;
            }
            .text{
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 400;
                line-height: 23px;
                color: #999999;
            }
        }
    }
    .l-t::after,.l-t::before,
    .r-t::after,.r-t::before,
    .r-b::after,.r-b::before,
    .l-b::after,.l-b::before{
        content:'';
        position: absolute;
        border: 1px dashed #28BBAC;
    }

    .l-t::after{
        top: 69px;
        right: -175px;
        width: 175px;
        height: 0px;
    }
    .l-t::before{
        top: 69px;
        right: -175px;
        width: 0px;
        height: 58px;
    }
    .r-t::after{
        top: 69px;
        left: -175px;
        width: 175px;
        height: 0px;
    }
    .r-t::before{
        top: 69px;
        left: -175px;
        width: 0px;
        height: 58px;
    }
    .l-b::after{
        bottom: 69px;
        right: -175px;
        width: 175px;
        height: 0px;
    }
    .l-b::before{
        bottom: 69px;
        right: -175px;
        width: 0px;
        height: 58px;
    }
    .r-b::after{
        bottom: 69px;
        left: -175px;
        width: 175px;
        height: 0px;
    }
    .r-b::before{
        bottom: 69px;
        left: -175px;
        width: 0px;
        height: 58px;
    }
`
export const CenterBox = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 258px;
    height: 258px;
    background: #a9e4de;
    border-radius: 50%;
    &>div{
        padding: 0 !important;
        width: 196px !important;
        height: 196px !important;
        background: #28BBAC;
        border-radius: 50% !important;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p:nth-of-type(1){
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 300;
            line-height: 28px;
            color: #FFFFFF;
        }
        p:nth-of-type(2){
            font-size: 30px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 48px;
            color: #FFFFFF;
        }
        p:nth-of-type(3){
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 300;
            line-height: 18px;
            color: #FFFFFF;
        }
    }
`
