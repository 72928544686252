import React from 'react'
import styled from 'styled-components'

export const Container = styled.div`
    padding: 80px 0 63px 0;
    background-color: #F5F5F5;
`
export const Main = styled.div`
    width: 1200px;
    margin: 0 auto;
    .title{
        &>p:nth-of-type(1){
            color: #EDEDED;
        }
    }
`
export const Content = styled.div`
    display: flex;
    padding-top: 84px;
    .l{
        padding: 0 400px 0 20px;
        ul{
            li{
                font-size: 40px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                width: 100px;
                height: 100px;
                line-height: 100px;
                text-align: center;
                border-radius: 8px;
                transform: rotate(45deg);
                position: relative;
                span.idx{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%) rotate(-45deg);
                }
                span.text{
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    position: absolute;
                    top: 4px;
                    right: -285px;
                    transform: rotate(-45deg);
                    transform-origin: -285px  9px;
                }
                span.text:before{
                    content:'';
                    width: 200px;
                    height: 2px;
                    background-color: #ccc;
                    position: absolute;
                    top: 50%;
                    left: -220px;                
                    transform: translateY(-50%);
                }
                span.text:after{
                    content:'';
                    width: 9px;
                    height: 9px;
                    background-color: #ccc;
                    position: absolute;
                    top: 50%;
                    left: -20px;                
                    transform: translateY(-50%);
                    border-radius: 50%;
                }
            }
            li:nth-of-type(even){
                transform: translate(80px) rotate(45deg);
                span.text{
                    right: -205px;
                    transform-origin: -205px  9px;
                }
                span.text:before{
                    content:'';
                    width: 120px;
                    height: 2px;
                    background-color: #ccc;
                    position: absolute;
                    top: 50%;
                    left: -140px;                
                    transform: translateY(-50%);
                }
            }
        }
    }
    .images{
        div{
            text-align: end;
        }
        p{
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #231815;
            line-height: 20px;
            padding-bottom: 18px;
            text-align: start;
        }
        .image-2 img{
            box-shadow: 0px 0px 20px #ccc;
        }
    }
`