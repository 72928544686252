import React from 'react'
import {Title} from '../../style'
import {Container} from './style'
//2类企业共赢
export default () => {

    let data3 = [
        {
            title:'职场稀缺人才孵化',
            text:'提供大数据、新媒体、电子竞技、 区块链等行业人才职业教育培养+孵化',
        },
        {
            title:'跨境电商人才输送',
            text:'渔人计划15天培养跨境电商人才，已为跨境电商企业输送人才10000+',
        },
    ]

    return <Container>
            <Title>
                <p><span>2</span>类企业共赢</p>
            </Title>
            <ul>
                {
                    data3.map(({title,text},index)=>{
                        return <li key={index}>
                            <div>
                                <img src={require(`assets/images/index/ourService/service3-${index+1}.png`)}/>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <p>{title}</p>
                                        <img src={require(`assets/images/index/ourService/service3-num-${index+1}.png`)}/>
                                    </div>
                                    <span></span>
                                </div>
                                <p>{text}</p>
                            </div>
                        </li>
                    })
                }
            </ul>
        </Container>
}
