import React from 'react';
// import {Main} from './style'
import Nav from 'components/nav' 
import Banner from './children/banner';
import OneStop from './children/oneStop';
import Learn from './children/learn';
import Practise from './children/practise';
import SupplyChain from './children/supplyChain';
import Employment from './children/employment';
import School from './children/school';
import OnTrial from './children/onTrial';
import Footer from 'components/footer';

 
export default (props) => {
    return <>
        <Nav {...props}/>
        <Banner/>
        <OneStop/>
        <Learn/>
        <Practise/>
        <SupplyChain/>
        <Employment/>
        <School/>
        <OnTrial/>
        <Footer/>
    </>
}
