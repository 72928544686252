import React from 'react'
import styled from 'styled-components'

export const Main = styled.div`
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 120px;
    ul{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        li{
            width: 80px;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            margin-bottom: 40px;
            box-sizing: border-box;
            margin-right: 40px;
            &:nth-of-type(10),&:nth-of-type(20){
                margin-right: 0px;
            }
            img{
                width: 100%;
            }
        }
    }
`
export const Title = styled.div`
    padding-bottom: 50px;
    text-align: center;
    p:nth-of-type(1){
        font-size: 58px;
        line-height: 58px;
        font-family: Arial;
        font-weight: bold;
        color: #F3F3F3;
    }
    p:nth-of-type(2){
        font-size: 36px;
        line-height: 36px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #343434;
        transform: translateY(-30px);
    }
`