import React,{useState} from 'react'
import {Title} from '../../style';
import {Container,Main,List} from './style';
import LazyImg from 'components/lazyLoad/lazyImg';
 
export default () => {
    let [dom,setDom] = useState(null);
    let data = [
        {
            name:'鲸学',
            content:[
                '跨境电商线上学习系统',
                '8大学习功能，助力高校课程实训'
            ]
        },
        {
            name:'鲸练',
            content:[
                '跨境电商模拟实操系统',
                '1:1仿真平台，助力高校课程实操'
            ]
        },
        {
            name:'鲸品',
            content:[
                '跨境电商供应链实战系统',
                '1000+供应链产品，助力高校学习实战'
            ]
        },
        {
            name:'鲸聘',
            content:[
                '跨境电商人才就业系统',
                '4000+合作企业，助力高校学生就业'
            ]
        },
    ]

    return <Container>
        <Main ref={el=>setDom(el)}>
            <Title>
                <p>COLLEGE TRAINING</p>
                <p>高校实训问题  一站解决</p>
                <p>实训 · 实操 · 实战 · 就业</p>
            </Title>
            <List>
                {data.map(({name,content},index)=>(<li key={index}>
                    <div className='icon'>
                        <LazyImg container={dom} src={`assets/icon/one-stop-icon${index+1}.png`}/>
                    </div>
                    <p className='name'>{name}</p>
                    {content.map((text,index2)=><p key={index2} className='text'>{text}</p>)}
                </li>))}
            </List>
        </Main>
    </Container>
}
