import React from 'react'
import Nav from 'components/nav' 
import Footer from 'components/footer'
import Banner from './children/banner'
import Group from './children/group'
import Advantage from './children/advantage'
// import Enterprise from './children/enterprise'
import School from './children/school'
import BBanner from './children/bBanner'

export default (props) => {
    return <>
        <Nav {...props}/>
        <Banner/>
        <Group/>
        <Advantage/>
        {/* <Enterprise/> */}
        <School/>
        <BBanner/>
        <Footer/>
    </>
}