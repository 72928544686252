import React from 'react'
import styled from 'styled-components'
import bg1 from 'assets/images/index/ourVision/bg-1.png'

export const Main = styled.div`
    width: 100%;
    max-width: 1920px;
    min-width: 1200px;
    margin: 0 auto;
    img{
        width: 100%;
    }
`