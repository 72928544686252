import React from 'react'
import styled from 'styled-components'
import bg from 'assets/images/index/development/bg.png'

export const Main = styled.div`
    max-width: 1920;
    min-width: 1200px;
    &>p{
        text-align: center;
    }
    &>p:nth-of-type(1){
        font-size: 58px;
        font-family: Arial;
        font-weight: bold;
        color: #F3F3F3;
        line-height: 36px;
    }
    &>p:nth-of-type(2){
        font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #343434;
        line-height: 36px;
        transform: translateY(-18px);
    }
`
export const Container = styled.div`
    width: 100%;
    height: ${({scale})=>792 * scale}px;
    overflow: hidden;
`
export const Content = styled.div`
    width: 1920px;
    height: 792px;
    background: url(${bg}) no-repeat;
    background-position: left bottom;
    background-size: 100%;
    transform-origin: top left;
    transform: scale(${({scale})=>scale});
    position: relative;
    `
export const Item = styled.div`
    width: 190px;
    padding: 20px 15px 10px 15px;
    box-sizing: border-box; 
    border-radius: 10px;    
    background: #FFFFFF;
    box-shadow: 0px 8px 31px 0px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: ${({top})=>top}px;
    left: ${({left})=>left}px;
    &::after{
        content: '';
        width:20px;
        height:20px; 
        background: #FFFFFF;
        box-shadow: 0px 8px 31px 0px rgba(0, 0, 0, 0.1);
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%,50%) rotate(45deg);
    }
`
export const Title = styled.p`
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    margin-bottom: 15px;
`
export const Text = styled.p`
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 24px;
    margin-bottom: 15px;
`
export const Number = styled.p`
    position: absolute;
    bottom: 0;
    transform: translate(40px,100px);
    font-size: 36px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 36px;
`