import React, { useRef, useEffect, useCallback, useState } from 'react'
import Nav from 'components/nav';
import Banner from './children/banner';
import Query from './children/query';
import Result from './children/result';
import Footer from 'components/footer';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import AJAX from "api/api";

export default (props) => {
  let [result, setResult] = useState(null);
  let [credentialsNum, setCredentialsNum] = useState(null);
  let [loaling, setLoaling] = useState(false);
  useEffect(() => {
    // console.log(props.location.state);
    if (props.location.state) {
      // setResult(props.location.state.data)
      if (!credentialsNum) {
        setCredentialsNum(props.location.state.credentialsNum)
        getCredentialsInfo()
      }
    } else {
      if (credentialsNum) setCredentialsNum('')
    }
  }, [props, credentialsNum])
  const getCredentialsInfo = () => {
    let params = {
      ...props.location.state.data
    }
    setLoaling(true)
    AJAX.login.getCredentialsInfo(params).then(res => {
      console.log(res);
      if (res.code == 999) {
        props.history.push('/certificate')
      } else if (res.code == -400) {
        setResult(null)
      }
      else {
        setResult(res.data)
      }
      setLoaling(false)
    }).catch(err => {
      if (err.code == '-1') {
        setResult(null)
        setLoaling(false)
      }
    })
  }


  return (
    <div className='certificate'>
      <Nav {...props} />
      <Banner />
      <div className='inner'>
        <h2>伍壹叁职业技能结业证书查询</h2>
        {
          credentialsNum ? (
            <Result result={result} loaling={loaling} {...props} credentialsNum={credentialsNum} />
          ) : <Query {...props} setResult={setResult} />
        }

      </div>
      <Footer />
    </div>
  )
}