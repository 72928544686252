import React from 'react'
import {Bg,Main,Top,Bottom} from './style'
import { SwiperSlide } from 'swiper/react';
import MySwiper from 'components/swiper'

let data = [
    {
        num:60,
        text:'新型企业联盟'
    },
    {
        num:200,
        text:'深度合作高校'
    },
    {
        num:10,
        text:'人才培养方向'
    },
    {
        num:20000,
        text:'稀缺人才培养'
    },
]

let data2 = [
    {
       text1:'深圳职业技术学院' ,
       text2:'校企合作人才培养基地' 
    },
    {
       text1:'深圳职业技术学院' ,
       text2:'深圳职业技术学院校外实训基地' 
    },
    {
       text1:'深圳职业技术学院' ,
       text2:'技术大师工作站' 
    },
    {
       text1:'新安职业技术学院' ,
       text2:'技术大师工作站' 
    },
    {
       text1:'广州松田职业学院' ,
       text2:'实习基地' 
    },
    {
       text1:'九江学院' ,
       text2:'创新与创业实践基地' 
    },
    {
       text1:'湖南人文科技学院' ,
       text2:'跨境电商专业校企联合办学基地' 
    },
    {
       text1:'湖南女子学院' ,
       text2:'校企合作人才培育基地' 
    },
    {
       text1:'伍壹叁教育' ,
       text2:'人才孵化基地' 
    },
    {
       text1:'伍壹叁教育集团' ,
       text2:'优秀校企合作单位' 
    },
    {
       text1:'成都东软学院' ,
       text2:'实践教学基地' 
    },
]


export default class extends React.Component{
    constructor(props){
        super(props)
        this.container = null
    }

    render(){
        return <Bg>
        <Main>
            <Top>
                <p>WHO ARE WE</p>
                <p>我们是谁</p>
                <div>
                    <div>
                        <p><span>513集团</span>—— 助力中国企业培养稀缺人才</p>
                        <p>513集团成立于2006年，专注于为中国企业培养稀缺人才，提供人才培养、猎聘、企业培训、咨询、孵化、联合运营、供应链等一站式综合服务。2020年，513集团再度升级，携手哈工产教融合、四叶草安全、零时科技、香蕉智能等高科技企业巨头企业，在大数据、网络安全、区块链技术、AI人工智能、直播电商领域高度合作，共建开放、共享、智慧的513教育生态圈。</p>
                    </div>
                    <ul>
                        {
                            data.map(({num,text},index)=>{
                                return <li key={index}>
                                    <span>{`${num}+`}</span>
                                    <span>{text}</span>
                                </li>
                            })
                        }
                    </ul>
                </div>
            </Top>
            <Bottom>
                <p>荣誉资质</p>
                <MySwiper
                    loop
                    autoplay={{
                        delay:1500
                    }}
                    centeredSlides={0}
                    slidesPerView={4}
                >
                    {data2.map(({text1,text2},index)=>{
                            return <SwiperSlide key={index}>
                                <div>
                                    <div>
                                        <img src={require(`assets/images/index/whoAreWe/honor-${index+1}.png`)}/>
                                    </div>
                                    <div>
                                        <p>{text1}</p>
                                        <p>{text2}</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        })
                    }
                </MySwiper>
            </Bottom>
        </Main>
    </Bg>
    }
}