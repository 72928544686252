import React from 'react'
import { Title } from '../../style'
import {Main,Content,ControlBox,PreBtn,NextBtn,MyPagination} from './style.js'
import { SwiperSlide } from 'swiper/react';
import MySwiper from 'components/swiper'

export default class extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            realIndex:0
        }
        this.swiper = null;
        this.swiperpaginations = null;
        this.data = [
            [
                {
                    text:'南昌航空大学科技学院来访513集团'
                },
                {
                    text:'湘南学院来访513集团'
                },
                {
                    text:'深职院来访513集团'
                },
            ],
            [
                {
                text:'广东职业技术学院来访513集团'
                },
                {
                    text:'广州南洋理工职业学院来访513集团'
                },
                {
                    text:'湘南学院来访513集团'
                }
            ],
            [
                {
                    text:'湖南文理学院芙蓉学院来访513集团'
                },
                {
                    text:'湖南人文科技学院来访513集团'
                },
                {
                    text:'江西财经大学现代经济管理学院来访513集团'
                }
            ],
            [
                {
                    text:'九江学院来访513集团'
                },
                {
                    text:'九江学院外国语学院来访513集团'
                },
                {
                    text:'新安职业技术学院汪校长一行莅临513集团'
                }
            ]
        ]
    }

    componentDidMount(){
        this.swiperpaginations = this.swiper.$el[0].getElementsByClassName('swiper-pagination')[0].getElementsByClassName('swiper-pagination-bullet');
    }

    handleSwitch = index => {
        this.swiperpaginations[index].click()
    }

    handlePrev = () => {
        this.swiper.navigation.prevEl.click()
    }
    handleNext = () => {
        this.swiper.navigation.nextEl.click()
    }

    handleChange = (swiper) => {
        this.setState({
            ...this.state,
            realIndex:swiper.realIndex
        })
    }

    getSwiper = (swiper) => {
        this.swiper = swiper
    }

    render(){
        return <Main>
            <Title className='title'>
                <p>UNIVERSITY VISIT</p>
                <p>高校来访</p>
            </Title>
            <Content>
                <ControlBox>
                    <PreBtn onClick={this.handlePrev}/>
                    <MyPagination>
                        {this.data.map((item,index)=><li key={index} className={index === this.state.realIndex ? 'current' : ''} onClick={()=>{this.handleSwitch(index)}}></li>)}
                    </MyPagination>
                    <NextBtn onClick={this.handleNext}/>
                </ControlBox>
                <MySwiper
                    loop
                    autoplay
                    navigation
                    centeredSlides={0}
                    slidesPerView={1}
                    spaceBetween={50}
                    pagination={{ clickable: true }}
                    onSlideChange={this.handleChange}
                    getSwiper={this.getSwiper}
                >
                    {this.data.map((item,index)=>{
                            return <SwiperSlide key={index}>
                                {item.map(({img,logo,text},index2)=>{
                                    return <div key={index2}>
                                            <div className='img'>
                                                <img src={require(`assets/images/communication/visit/img-${index*3 + index2 + 1}.png`)}/>
                                            </div>
                                            <div className='text'
                                            style={{
                                                background:`url(${require(`assets/images/communication/visit/logo-${index*3 + index2 + 1}.png`)}) no-repeat`,
                                                backgroundSize: `100px 100px`,
                                                backgroundPosition: `center 30px`
                                            }}>
                                                <p>{text}</p>
                                            </div>
                                        </div>
                                })}
                            </SwiperSlide>
                        })
                    }
                </MySwiper>
            </Content>
        </Main>
    }
}