import React from 'react'
import styled from 'styled-components'

export const Title = styled.div`
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    padding-top: 95px;
    &>p{
        text-align: center;
    }
    &>p:nth-of-type(1){
        font-size: 58px;
        font-family: Arial;
        font-weight: bold;
        color: #F3F3F3;
        line-height: 36px;
    }
    &>p:nth-of-type(2){
        font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #343434;
        line-height: 36px;
        transform: translateY(-18px);
    }
`