import React from 'react'
import styled from 'styled-components'

export const Main = styled.div`
    min-width: 1200px;
    margin: 0 auto;
    padding: 80px 0 0 0;
    img{
        display: block;
        width: 100%;
    }
`
export const Tabs = styled.div`
    display: flex;
    justify-content: center;
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 36px;
    padding: 30px 0 60px 0;
    span{
        display: inline-block;
        width: 1px;
        height: 36px;
        background-color: #333;
        margin: 0 36px;
    }
    p{
        cursor: pointer;
    }
    p.current{
        color: #333;
        position: relative;
    }
    p.current::before{
        content:'';
        position: absolute;
        bottom: -5px;
        left: 0;
        height: 6px;
        width: 100%;
        background: #28BBAC;
    }
`