import React from 'react'
import {Title} from '../../style'
import {Container,Part2Item} from './style'
//3种职业教育
export default () => {

    let data2 = [
        {
            title:'一、智能化线上教育培养',
            text:'解决高校、企业、学生痛点，打造智能化平台',
            color:'#41A0E1'
        },
        {
            title:'二、高校专业共建培育',
            text:'帮助高校实现“产、教、学”一体化产业升级',
            color:'#3A3C5E'
        },
        {
            title:'三、大学生创新创业扶持',
            text:'帮助大学生创业，提供技术、资源、资金全方位扶持',
            color:'#28BBAC'
        },
    ]

    return <Container>
            <Title>
                <p><span>3</span>种职业教育</p>
            </Title>
            <ul>
                {
                    data2.map(({title,text,color},index)=>{
                        return <Part2Item key={index} color={color}>
                            <div>
                                <p>{title}</p>
                                <p>{text}</p>
                            </div>
                            <div>
                                <img src={require(`assets/images/index/ourService/service2-${index+1}.png`)}/>
                            </div>
                        </Part2Item>
                    })
                }
            </ul>
        </Container>
}
