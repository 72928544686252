import React,{useEffect,useState} from 'react'
import {Main,Title,Container} from './style'
import MySwiper from 'components/swiper'
import { SwiperSlide } from 'swiper/react'
const maxCount = 20
//60+新型企业
export default () => {

    return <Main>
        <Container>
                <MySwiper
                    loop
                    autoplay
                    navigation
                    slidesPerView={1}   
                    initialSlide={0}   
                    spaceBetween={100}
                    >
                        <SwiperSlide>
                            <Title>
                                <p><span>3000+</span><span className="titleBold">合作企业</span></p>
                            </Title>
                            <ul>{
                                Array.from(new Array(20).keys()).map(i=>{
                                    return <li key={i}>
                                        <img src={require(`../../../../../assets/images/index/ourCoperation/enterprise/${i+1}.png`)}/>
                                    </li>
                                })
                            }</ul>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Title>
                                <p><span>50+</span><span className="titleBold">合作平台</span></p>
                            </Title>
                            <ul>{
                                Array.from(new Array(20).keys()).map(i=>{
                                    return <li key={i}>
                                        <img src={require(`../../../../../assets/images/index/ourCoperation/platform/${i+1}.png`)}/>
                                    </li>
                                })
                            }</ul>
                        </SwiperSlide>
                </MySwiper>
        </Container>
    </Main>
}
