import React from 'react'
import {Link} from 'react-router-dom';
import {Container,Main,Logo,Nav} from './style'
import logo from 'assets/images/nav/logo.png'

export default (props) => {
    const navData = [
        {
            name:'首页',
            path:'/'
        },
        {
            name:'政企学交流',
            path:'/communication'
        },
        {
            name:'产教融合',
            path:'/combine'
        },
        {
            name:'蓝鲸系统',
            path:'/whale'
        },
        {
            name:'证书查询',
            path:'/certificate'
        },
        // {
        //     name:'资讯分享',
        //     path:'/companyInfo'
        // },
        {
            name:'联系我们',
            path:'/aboutUs'
        }
    ]

    return <Container>
        <Main>
        <Logo>
            <img src={logo}/>
        </Logo>
        <Nav>
            {
                navData.map((item,index)=>{
                    let pathname = props.location.pathname
                    return <li key={index}>
                        <Link to={item.path}><span className={pathname === item.path ? 'current' : ''}>{item.name}</span></Link>
                    </li>
                })
            }
            <li className='learn'>
                <a target="_blank" href='https://ke.513.com/'><span>513云课堂</span></a>
                <div className='icon'>
                    <div>
                        <img src={require('assets/images/nav/ewmb.png')}/>
                    </div>
                </div>
            </li>
        </Nav>
    </Main>
    </Container>
}