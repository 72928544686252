import React,{useState} from 'react'
import LazyImg from 'components/lazyLoad/lazyImg';
import {Link} from 'react-router-dom';


export default ({pictureImage, createTime, content,title,sign}) => {
    let [container,setContainer] = useState('');

    return <li ref={el=>setContainer(el)}>
        <Link to={`/newDetails?sign=${sign}`}>
            <div className='img'>
                <LazyImg container={container} src={pictureImage}/>
            </div>
            <div className='content'>
                <p className="date">{createTime}</p>
                <p className="title">{title}</p>
                <p className="text">{content}</p>
            </div>
            </Link>
        </li>
}