import React from 'react'
import {Main} from './style'
import Industry from './children/industry' //6大新型产业
import Education from './children/education' //3种职业教育
import WinWin from './children/winWin'//2类企业共赢

export default class extends React.Component{
    constructor(props){
        super(props)
    }

    render(){
        return <Main>
            <p>OUR SERVICE</p>
            <p>我们的服务</p>
            <Industry/>
            <Education/>
            <WinWin/>
        </Main>
    }
}