import React from 'react'
import styled from 'styled-components'

export const Main = styled.div`
    width: 1200px;
    margin: 0 auto;
    ul.ant-pagination{
        display: flex;
        justify-content: center;
        padding: 80px 0 70px 0;
        li{
            margin-right: 20px;
        }
        li.ant-pagination-item-active{
            border: 1px solid #28BBAC;
            a{
                color: #28BBAC;
            }
        }
        .ant-pagination-prev:hover button,
        .ant-pagination-next:hover button
        {
            border: 1px solid #28BBAC;
        }
        .ant-pagination-next{
            margin-right: 0;
            button span{
                display: none;
            }
            button::after{
                content:'下一页';
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
                line-height: 24px;
            }
        }
        .ant-pagination-prev{
            button span{
                display: none;
            }
            button::after{
                content:'上一页';
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
                line-height: 24px;
            }
        }
    }
`
export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 50px;
    border-bottom: 1px solid #E6E6E6;
    .item{
        width: 574px;
        position: relative;
        height:100%;
        div{
            width: 574px;
            height: 145px;
            position: absolute;
            left: 0;
            bottom: 0;
            color: #fff;
            background: linear-gradient( rgba(0,0,0,0), rgba(0,0,0,1));
            opacity: 0.75;
            padding: 60px 40px 0 40px;
            box-sizing: border-box;
            .date{
                font-size: 24px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 24px;
                margin-bottom: 15px;
            }
            .text{
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 24px;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box; 
                -webkit-box-orient: vertical; 
                -webkit-line-clamp: 2; 
                overflow: hidden; 
            }
        }
    }
`
export const Content = styled.ul`
    padding-top: 40px;
    li:not(:nth-last-child(1)){
        border-bottom: 1px solid #E6E6E6;
        margin-bottom: 40px;
        padding-bottom: 40px;
    }
    li{
        a{
            display: flex;
        }
        .img{
            width: 368px;
            min-width: 368px;
            height: 207px;
            overflow: hidden;
            img{
                width: 100%;
                display: block;
            }
        }
        .content{
            padding: 20px 0 20px 33px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .date{
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
                line-height: 24px;
            }
            .title{
                font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                line-height: 24px;
            }
            .text{
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
                line-height: 24px;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box; 
                -webkit-box-orient: vertical; 
                -webkit-line-clamp: 2; 
                overflow: hidden; 
            }
        }
    }
`