import React from 'react'
import {Main,Container,Content,Item,Title,Text,Number} from './style'

export default class extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            scale:1
        }
        this.data = [
            {
                year:'2006',
                title:'集团成立',
                top:475,
                left:20,
                lists:[
                    '创立于香港，于同年布局外贸行业。'
                ],
            },
            {
                year:'2008',
                title:'通过ISO国际标准认证',
                top:390,
                left:230,
                lists:[
                    '集团正式成为质量管理体系符合国际标准的现代化企业，专注发展线下外贸出口业务。'
                ],
            },
            {
                year:'2011',
                title:'集团业务转型',
                top:325,
                left:453,
                lists:[
                    '创立的自有品牌畅销国外，集团开始从传统的线下外贸出口向线上B2B、B2C相融合的业务模式转型。'
                ],
            },
            {
                year:'2014',
                title:'2014年 转型初见成效，引领更多中国品牌出海',
                top:305,
                left:687,
                lists:[
                    '跨境电商业务覆盖海外16个国家，销售额破1亿元。'
                ],
            },
            {
                year:'2017',
                title:'2017年构建“教育—人才—孵化”513教育产业生态链',
                top:220,
                left:924,
                lists:[
                    '成立513电商学院，推出精品服务项目“渔人计划”，与100所高校达成人才合作项目，成为阿里巴巴官方人才培育基地。'
                ]
            },
            {
                year:'2018',
                title:'2018年伍壹叁教育集团进入高速发展',
                top:210,
                left:1177,
                lists:[
                    '完成A轮融资，并成立广西、云南校区。',
                    '为广西、云南区域经济发展、产业升级提供人才保障和智力支持。',
                ]
            },
            {
                year:'2019',
                title:'2019年伍壹叁教育集团开辟多元化、新型互联网人才培育新航道',
                top:170,
                left:1430,
                lists:[
                    '累计输送学员13000人与4000余家企业达成深度合作',
                    '研发的513教育APP正式上线。',
                ]
            },
            {
                year:'2020',
                title:'2020年联合新型行业巨头，共同构建开放、共享、智慧的513教育生态圈',
                top:76,
                left:1689,
                lists:[
                    '与哈工产教融合科技、四叶草网络安全、零时科技、香蕉智能科技、奥比中光企业，在大数据、网络安全、区块链技术、AI人工智能、网络直播等领域展开合作。',
                ]
            },
        ]
        this.main = null
    }

    componentDidMount(){
        this.resize()
        window.addEventListener('resize',this.resize)
    }
    componentWillUnmount(){
        window.removeEventListener('resize',this.resize)
    }

    resize = () => {
        let {width} = this.main.getBoundingClientRect()
        this.setState({
            scale: width / 1920
        })
    }

    getMainRef = el => {
        this.main = el;
    }


    render(){
        let {scale} = this.state
        return <Main ref={this.getMainRef}>
                <p>DEVELOPMENT PATH</p>
                <p>513发展历程</p>
                <Container scale={scale}>
                    <Content scale={scale}>
                        {
                            this.data.map(({year,title,lists,top,left},index)=>{
                                return <Item key={index} top={top} left={left}>
                                    <Title>{title}</Title>
                                    <Number>{year}</Number>
                                    {
                                        lists.map((text,index2)=>{
                                        return <Text key={index2}>{text}</Text>
                                        })
                                    }
                                </Item>
                            })
                        }
                    </Content>
                </Container>
            </Main>
    }
}