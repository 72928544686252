import React,{useState} from 'react'
import { Container,Item } from './style'
import LazyImg from 'components/lazyLoad/lazyImg';

export default ({item}) => {
    let [container,setContainer] = useState('');
    return <Container ref={el=>setContainer(el)}>
            {
                item.map((list,index2)=>{
                    return <Item key={index2}>
                        <div>
                            <LazyImg container={container} src={`assets/aboutUs/company/company-${list}.png`}/>
                        </div>
                    </Item>
                })
            }
        </Container>
}