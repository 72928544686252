const PIPE_LINE = Symbol("PIPE_LINE");

class Storage {
  static storageType = {
    local: "local",
    session: "session",
  };

  storageMap = {
    local: localStorage,
    session: sessionStorage,
  };

  [PIPE_LINE] = [];
  constructor(type = Storage.storageType.local, pipeLine = []) {
    this.initPipeLine(pipeLine);
    this.type = type;
  }

  initPipeLine(initValue) {
    this[PIPE_LINE] = [...initValue];
  }

  addPipeLineItem(cb) {
    this[PIPE_LINE] = [...this[PIPE_LINE], cb];
  }

  getPipeLine() {
    return Object.freeze(this[PIPE_LINE]);
  }

  clearPipeLine() {
    this[PIPE_LINE] = [];
  }

  pipe(fn) {
    this.addPipeLineItem(fn);
    return this.generateInstance();
  }

  parse() {
    this.addPipeLineItem(JSON.parse);
    return this.generateInstance();
  }

  stringify() {
    this.addPipeLineItem(JSON.stringify);
    return this.generateInstance();
  }

  generateInstance() {
    const pipeLine = this.getPipeLine();
    this.clearPipeLine();
    return new Storage(this.type, pipeLine);
  }

  generateValue(value) {
    return this.getPipeLine().reduce((result, item) => {
      return item(result);
    }, value);
  }

  get(key) {
    const value = this.storageMap[this.type].getItem(key);

    return this.generateValue(value);
  }

  set(key, value) {
    const resultValue = this.generateValue(value);

    this.storageMap[this.type].setItem(key, resultValue);
  }

  get local() {
    return new Storage(Storage.storageType.local, this.pipeLine);
  }
  get session() {
    return new Storage(Storage.storageType.session, this.pipeLine);
  }
}

export default Storage;
