import React from 'react'
import styled from 'styled-components'

export const Container = styled.div`
    margin-bottom: 50px;
    ul{
        display: flex;
        justify-content: space-between;
    }
    img{
        display: block;
    }
`
export const Part2Item = styled.li`
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid  ${({color})=> color};
    transition: all ease 0.3s;  
    div:nth-of-type(1){
        padding: 40px 0;
        background-color: ${({color})=> color};
        p{
            text-align: center;
        }
        p:nth-of-type(1){
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 36px;
        }
        p:nth-of-type(2){
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 36px;
            opacity: 0.6;
        }
    }
    &:hover{
        box-shadow: 0px 10px 37px 0px rgba(20,20,20,0.3);    }
`