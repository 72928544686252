import React,{useState} from 'react'
import {Title} from '../../style';
import {Container,Main,Content} from './style';
import LazyImg from 'components/lazyLoad/lazyImg';
 
export default () => {
    let [dom,setDom] = useState(null);
    let data = [
        {
            text:'账号申请',
            color: '#28BBAC'
        },
        {
            text:'提供指导',
            color: '#04AEEC'
        },
        {
            text:'提供产品',
            color: '#FFD400'
        },
        {
            text:'提供发货',
            color: '#28BBAC'
        },
        {
            text:'提供分成',
            color: '#F56E60'
        },
        {
            text:'支持双创',
            color: '#FFD400'
        },
        {
            text:'扶持创业',
            color: '#04AEEC'
        },
        {
            text:'积累经验',
            color: '#556E81'
        },
    ];

    return <Container>
        <Main ref={el=>setDom(el)}>
            <Title className='title'>
                <p>SUPPLY CHAIN COMBAT</p>
                <p>鲸品-跨境电商供应链实战系统</p>
                <p><span>1000+</span>供应链产品，助力高校学习实战</p>
            </Title>
            <Content>
                <div className='l'>
                    <ul>{
                        data.map((item,index)=>
                        <li key={index} style={{backgroundColor:item.color}}>
                            <span className='idx'>{`0${index+1}`}</span>
                            <span className='text'>{item.text}</span>
                        </li>)    
                    }</ul>
                </div>
                <div className='images'>
                    <div>
                        <LazyImg container={dom} src={'assets/img/supply-chain-1.png'}/>
                    </div>
                    <div className='image-2'>
                        <p>供应链产品采集页面</p>
                        <LazyImg container={dom} src={'assets/img/supply-chain-2.png'}/>
                    </div>
                </div>
            </Content>
        </Main>
    </Container>
}
