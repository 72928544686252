import React from 'react'
import styled from 'styled-components'

export const Main = styled.div`
    max-width: 1920px;
    min-width: 1200px;
    margin: 0 auto;
    img{
        display: block;
        width: 100%;
    }
`