import React from 'react'
import {Title} from '../../style'
import {Part2} from './style'
//513全球电商小镇（产学研融合-创造新经济）
export default () => {
    return <Part2>
            <Title>513全球电商小镇（产学研融合-创造新经济）</Title>
            <div>
                <img src={require(`assets/images/index/ourVision/part2-img-1.png`)}/>
                <p>伍壹叁教育集团联合龙光地产、轨道交通集团等行业龙头企业与政府，合作布局地方跨境电商产业基地，助力政府打造跨境电商产业圈，带动地方经济发展。产业基地建设有：跨境电商供应链、物流、人才孵化、结算、国内外网红培养基地、一带一路展示大厅等，把伍壹叁教育集团“产业-教育-孵化”带到当地，帮助当地高校、政府、企业持续发展。</p>
            </div>
        </Part2>
}
