import React from 'react'
import styled from 'styled-components'
import bg1 from 'assets/images/index/ourVision/bg-1.png'

export const Main = styled.div`
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    padding: 80px 0 0 0;
    &>p{
        text-align: center;
    }
    &>p:nth-of-type(1){
        font-size: 58px;
        font-family: Arial;
        font-weight: bold;
        color: #F3F3F3;
        line-height: 36px;
    }
    &>p:nth-of-type(2){
        font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #343434;
        line-height: 36px;
        transform: translateY(-18px);
    }
`
export const Title = styled.p`
    font-size: 26px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #3DC2B4;
    line-height: 36px;
    margin: 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;    
    &::before{
        display: inline-block;
        content:'';
        width: 150px;
        height: 2px;
        line-height: 36px;
        background-color: #28BBAC;
        margin-right: 15px;
    }
    &::after{
        display: inline-block;
        content:'';
        width: 150px;
        height: 2px;
        background-color: #28BBAC;
    }
`