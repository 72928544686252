import React from 'react'
import styled from 'styled-components'

export const Container = styled.div`
    padding: 100px 0 127px 0;
    background-color: #fff;
`
export const Main = styled.div`
    width: 1200px;
    margin: 0 auto;
`
export const List = styled.ul`
    display:flex;
    justify-content: space-between;
    padding: 100px 0 0 0;
    li{
        width: 270px;
        height: 338px;
        background: #FFFFFF;
        border-radius: 16px;
        padding: 66px 0px 60px 0px;
        box-shadow: 0px 10px 33px 0px rgba(62, 62, 62, 0.13);
        .icon{
            width: 90px;
            height: 90px;
            margin: 0 auto;
            margin-bottom: 35px;
            img{
                width: 100%;
                height: 100%;
            }
        }
        p{
            text-align: center;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
        }
        .name{
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin-bottom: 5px;
        }
    }
`