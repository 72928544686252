import React, { useRef, useEffect, useCallback, useState } from 'react'
import { Button, Form, Input, Popover } from 'antd';
import AJAX from "api/api";
import tip_icon from 'assets/images/certificate/tip_icon.png'
import tip1 from 'assets/images/certificate/tip1.png'
import tip2 from 'assets/images/certificate/tip2.png'

export default (props) => {
  let formRef = useRef();
  const [form] = Form.useForm();
  let [img, setImg] = useState();
  let [codeErr, setCodeErr] = useState('');
  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };
  const getCodeImgUrl = useCallback(async () => {
    AJAX.login.getCodeImgUrl().then(res => {
      // console.log(res);
      setImg(res)
    })
  }, [])
  useEffect(() => {
    getCodeImgUrl();
  }, [getCodeImgUrl])
  const suffix = useCallback(() => {
    return <img onClick={() => { getCodeImgUrl() }} style={{ width: '81px', height: '32px', cursor: 'pointer' }} src={img}></img>
  }, [img])
  const next = () => {
    formRef.current.validateFields().then(async (params) => {
      AJAX.login.queryCredentialsInfo(params).then(res => {
        if (res.code == 999) {
          props.history.push({
            pathname: '/certificate',
            state: {
              credentialsNum: params.credentialsNum,
              data: null
            }
          })
        } else {
          props.history.push({
            pathname: '/certificate',
            state: {
              credentialsNum: params.credentialsNum,
              data: {
                tokenKey: res.data,
                name: params.name,
              }
            }
          })
        }

      }).catch(err => {
        // console.log(err);
        if (err.code == '-1') {
          setCodeErr('验证码有误')
          formRef.current.validateFields()
        }
        getCodeImgUrl()
      })


    })
  }
  const content = (img) => {
    return (
      <img className='certificate_tip' src={img} />
    )
  }
  return (
    <div className='certificate_query'>
      <Form
        form={form}
        ref={formRef}
        {...formItemLayout}
        autoComplete="off"
      >
        <div style={{ position: 'relative' }}>
          <Form.Item
            name="credentialsNum"
            label="证书编号："
            required={false}
            rules={[
              {
                required: true,
                message: '请输入证书编号',
              },
            ]}
          >
            <Input maxLength={30} placeholder='请输入证书编号' />
          </Form.Item>
          <Popover placement="right" content={content(tip1)} title="">
            <img className='tip_icon' src={tip_icon} />
          </Popover>
        </div>

        <div style={{ position: 'relative' }}>
          <Form.Item
            name="name"
            label="姓名："
            required={false}
            rules={[
              {
                required: true,
                message: '请输入证书对应姓名',
              },
            ]}
          >
            <Input maxLength={30} placeholder='请输入证书对应姓名' />
          </Form.Item>
          <Popover placement="right" trigger="hover" content={content(tip2)} title="">
            <img className='tip_icon' src={tip_icon} />
          </Popover>
        </div>

        <Form.Item
          name="code"
          label="验证码："
          required={false}
          rules={[
            {
              required: true,
              message: '请输入验证码',
            },
            {
              validator: (rule, value) => {
                if (codeErr) return Promise.reject(codeErr);
                return Promise.resolve();
              }
            }
          ]}
        >
          <Input placeholder='请输入验证码' maxLength={4} onChange={() => { setCodeErr('') }} suffix={suffix()} />
        </Form.Item>

        <Form.Item colon={false} label=" ">
          <Button type="primary" htmlType="submit" onClick={next}>查询</Button>
        </Form.Item>

      </Form>
      <div className='tips'>
        <p><span>*</span> 注意：</p>
        <p>1. 查询证书需经权属人同意。</p>
        <p>2. 查询证书结果不得用于违背权属人意愿之用途。</p>
      </div>
    </div>
  )
}