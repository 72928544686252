import React,{useState} from 'react'
import LazyImg from 'components/lazyLoad/lazyImg';
import {Link} from 'react-router-dom';

export default ({idx,date,title,text,sign}) => {
    let [container,setContainer] = useState('');
    return <li ref={el=>setContainer(el)}>
        <Link to={`/newDetails?sign=${sign}`}>
            <div className='img'>
                {/* <LazyImg container={container} src={`assets/companyInfo/img/plan-list-${idx}`}/> */}
            </div>
            <div className='content'>
                <p className="date">{date}</p>
                <p className="title">{title}</p>
                <p className="text">{text}</p>
            </div>
            </Link>
        </li>
}