import React from 'react'
import { Main,Mask,Bottom,CarouselTitle,CtrlBox,PreBtn,NextBtn,MyPagination} from './style'
import MySwiper from 'components/swiper'
import { SwiperSlide } from 'swiper/react'

export default class extends React.Component{
    constructor(props){
        super(props)
        this.data = [
            '贵阳市观山湖区区委书记汤辉莅临指导',
            '深圳市跨境电商协会会长王馨参观交流',
            '深职院商外学院副院长刘红燕带队考察',
            '数字广西企业代表团莅临513教育集团',
        ]
        this.state = {
            realIndex:0
        }
        this.swiper = null
        this.swiperpaginations = null
    }

    componentDidMount(){
        this.swiperpaginations = this.swiper.$el[0].getElementsByClassName('swiper-pagination')[0].getElementsByClassName('swiper-pagination-bullet');
    }

    handleSwitch = index => {
        this.swiperpaginations[index].click()
    }
    handleChange = (swiper) => {
        this.setState({
            ...this.state,
            realIndex:swiper.realIndex
        })
    }
    handlePrev = () => {
        this.swiper.navigation.prevEl.click()
    }
    handleNext = () => {
        this.swiper.navigation.nextEl.click()
    }
    getSwiper = (swiper) => {
        this.swiper = swiper
    }

    render(){
        return <Main>
        <MySwiper
                loop
                autoplay
                navigation
                slidesPerView={2}
                centeredSlides={true}
                pagination={{ clickable: true }}
                effect={'coverflow'}
                coverflowEffect={{
                    rotate: 0,
                    stretch: 50,
                    depth: 300,
                    modifier: 1,
                    slideShadows : true
                }}
                onSlideChange={this.handleChange}
                getSwiper={this.getSwiper}
                >
                    {   
                        this.data.map((item,index)=>{
                            return <SwiperSlide key={index}>
                                <Mask className='mask'/>
                                <img src={require(`assets/images/index/ourCoperation/photo/qi-${index+1}.jpg`)}/>
                            </SwiperSlide>
                        })
                    }
            </MySwiper>
            <Bottom>
                <CarouselTitle>
                    <p>{this.data[this.state.realIndex]}</p>
                </CarouselTitle>
                <CtrlBox>
                    <PreBtn onClick={this.handlePrev}/>
                    <MyPagination>
                        {
                            this.data.map((item,index)=>{
                            return <li 
                                className={this.state.realIndex === index ? 'current' : ''} 
                                key={index}
                                onClick={()=>{this.handleSwitch(index)}}
                                ></li>
                            })
                        }
                    </MyPagination>
                    <NextBtn onClick={this.handleNext}/>
                </CtrlBox>
            </Bottom>
        </Main>
    }
}
