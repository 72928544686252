import React from 'react'
import styled from 'styled-components'

export const Container = styled.div`
    img{
        display: block;
        transition: all ease 0.3s;
    }
    li{
        display: flex;
        &:not(:nth-last-child(1)){
            margin-bottom: 20px;
        }
        &:nth-of-type(2){
            display: flex;
            flex-flow: row-reverse;
            &>div:nth-of-type(2){
                background: #F5F5F5;
                &>div{
                    display: flex;
                    align-items: flex-end;
                    div{
                        flex-flow: row-reverse;
                        justify-content: flex-end;
                        p{
                            padding-left: 15px;
                            padding-right: 0;
                        }
                    }
                }
            }
        }
        &>div:nth-of-type(1){
            overflow: hidden;
            cursor: pointer;
        }
        &>div:nth-of-type(1):hover img{
            transform: scale(1.2);  
        }
        &>div:nth-of-type(2){
            background: #F5F5F5;
            box-sizing: border-box; 
            div p{
                padding-right: 15px;
            }
        }
        &>div{
            width: 50%;
            &:nth-of-type(2){
                padding: 80px 70px 80px 100px;
                &>p{
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    line-height: 24px;
                }
                &>div{
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 30px;
                    div{
                        display: flex;
                        margin-bottom: 30px;
                    }
                    span{
                        width: 100px;
                        height: 1px;
                        background-color: #666;
                    }
                    p{
                        display: flex;  
                        align-items: center; 
                        font-size: 20px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                    }
            }
        }
    }

`