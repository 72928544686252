import React from 'react'
import styled from 'styled-components'
import bg1 from 'assets/images/index/ourVision/bg-1.png'

export const Part2 = styled.div`
    max-width: 1650px;
    min-width: 1200px;
    height: 820px;
    margin: 0 auto;
    background: url(${bg1}) no-repeat;
    background-position: center;
    &>p{
        padding-top: 40px;
        margin-top: 0;
    }
    &>div{
        width: 1200px;
        height: 525px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        p{
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            line-height: 50px;
            padding-left: 26px;
        }
    }
`