import React from 'react'
import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    min-width: 1200px;
    .swiper-slide{
        img{
            width: 100%;
        }
    }
    .swiper-button-next,.swiper-button-prev{
        display: none;
    }
    .swiper-pagination-bullet{
        background-color: rgba(203, 203, 203, 1);
    }
    .swiper-pagination-bullet-active{
        background-color: #fff;
    }
`