import Ajax from "./request";

function connect(params) {
  let url = `?`;
  for (let key in params) {
    url = `${url}${key}=${params[key]}&`;
  }
  return url;
}

export default {
  Journalism: {
    getAllNewsList(params) {
      // 获取新闻列表
      return Ajax.ajaxs("/news/getAllNewsList", params);
    },
    getByNewsId(params) {
      // 获取新闻详情
      return Ajax.ajaxs("/news/getByNewsId", params);
    }
  },
  login: {
    signIn(params) {
      // 登录
      return Ajax.ajaxs("/user/login", params);
    },
    signUp(params) {
      // 注册
      return Ajax.ajaxs("/user/register", params);
    },
    getBackPassword(params) {
      // 找回密码
      return Ajax.ajaxs("/user/reset/password", params);
    },
    async getCodeImgUrl() {
      // 图片验证码
      const res = Ajax.ajaxs("/baseVerify/getVerifyCode", {}, "GET", true);
      return res
    },
    getPhoneCode(params) {
      //短信验证码
      return Ajax.ajaxs("/sendMobileCode", params);
    },
    queryCredentialsInfo(params) {
      //查询证书 
      return Ajax.ajaxs("/credentials/queryCredentialsInfo", params, "GET");
    },
    getCredentialsInfo(params) {
      //获取证书信息 
      return Ajax.ajaxs("/credentials/getCredentialsInfo", params, "GET");
    },
    logOut() {
      //退出登录
      return Ajax.ajaxs("/user/logout", {});
    },
    getUserInfo(key = '') {
      //获取用户信息
      return Ajax.ajaxs(`/user/getUserInfo?sessionKey=${key}`, {}, "GET");
    },
  }
};
