import React,{useEffect,useState} from 'react'
import io from 'utils/intersectionObserver';
 
export default ({container,src,...rest}) => {
    let [img,setImg] = useState('');
    useEffect(()=>{
        if(!container) return;
        let dom = container;
        if(io.io){
            io.observe(dom,() => {
                setImg(src);
            });
        }else{        
            setImg(src);
        }
        return () => {
            io.unobserve(dom);
        }
    },[container]);
    return <img src={img} {...rest}/>
}
