import React from 'react'
import styled from 'styled-components'

export const Main = styled.div`
    background: url(${'assets/aboutUs/map/map.png'}) no-repeat;
    background-size: cover;
    background-position: center;
`
export const Content = styled.div`
    width: 1200px;
    height: 565px;
    margin: 0 auto;
    &>div{
        width: 574px;
        height: 565px;
        background: #28BBAC;
        padding: 65px 0 0 50px;
        box-sizing: border-box; 
        p.en{
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 54px;
        }
        p.zh{
            font-size: 36px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
            position: relative;
        }
        p.zh::after{
            position: absolute;
            left: 0;
            bottom: -20px;
            content:'';
            width: 276px;
            height: 1px;
            background: #FFFFFF;
        }
        ul{
            padding-top: 70px;
            li:not(:nth-last-child(1)){
                margin-bottom: 40px;
            }
            li{
                position: relative;
                padding-left: 40px;
                p:nth-of-type(1){
                    font-size: 22px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 40px;
                }
                p:nth-of-type(2){
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 24px;
                }
            }
            li.address::before{
                position: absolute;
                width: 30px;
                height: 30px;
                left: 0;
                top: 5px;
                content:'';
                background: url(${'assets/aboutUs/map/icon_address.png'}) no-repeat;
                background-size: 100%; 
            }
            li.phone::before{
                position: absolute;
                width: 30px;
                height: 30px;
                left: 0;
                top: 5px;
                content:'';
                background: url(${'assets/aboutUs/map/icon_phone.png'}) no-repeat;
                background-size: 100%; 
            }
            li.email::before{
                position: absolute;
                width: 30px;
                height: 30px;
                left: 0;
                top: 5px;
                content:'';
                background: url(${'assets/aboutUs/map/icon_email.png'}) no-repeat;
                background-size: 100%; 
            }
        }
    }
`