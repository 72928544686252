import React from 'react'
import styled from 'styled-components'

export const Part1 = styled.div`
    padding-bottom: 40px;
`
export const Item = styled.li`
    display: flex;
    background-color: ${({color})=>color}};
    box-shadow: 0px 10px 37px 0px rgba(20, 20, 20, 0.09);
    border-radius: 8px;
    padding: 30px 0 20px 50px;
    &:not(:nth-last-child(1)){
        margin-bottom: 30px;
    }
    &>div:nth-of-type(1){
        box-sizing: border-box;
        padding-right: 40px;
    }
    &>div:nth-of-type(2){
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 15px 0;
        p:nth-of-type(1){
            font-size: 28px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            line-height: 30px;
        }
        p:nth-of-type(2){
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            line-height: 30px;
        }
    }
`