import React from 'react'
import Nav from 'components/nav' 
import Footer from 'components/footer'
import Banner from './children/banner'
import InfoCenter from './children/infoCenter'

export default (props) => {
    return <>
        <Nav {...props}/>
        <Banner/>
        <InfoCenter/>
        <Footer/>
    </>
}