import React,{useState} from 'react'
import {Container,Main,CenterBox} from './style'
import LazyImg from 'components/lazyLoad/lazyImg';

export default () => {
    let [box,setBox] = useState('');
    return <Container ref={el=>setBox(el)}>
            <Main>
            <div>
                <div className='l-t'>
                    <LazyImg container={box} src={'assets/combine/icon-1.png'}/>
                    <p className='title'>行业资深</p>
                    <p className='text'>深耕教育培训行业14年1000+新型企业合作</p>
                </div>
                <div className='r-t'>
                    <LazyImg container={box} src={'assets/combine/icon-2.png'}/>
                    <p className='title'>教育扶持</p>
                    <p className='text'>5大稀缺行业人才培养IT、设计、电商、大数据、直播</p>
                </div>
            </div>
            <div>
                <div className='l-b'>
                    <LazyImg container={box} src={'assets/combine/icon-3.png'}/>
                    <p className='title'>联合认证</p>
                    <p className='text'>多个学习对口认证通道人社局、工信部、阿里巴巴、亚马逊等资质认证</p>
                </div>
                <div className='r-b'>
                    <LazyImg container={box} src={'assets/combine/icon-4.png'}/>
                    <p className='title'>产业联营</p>
                    <p className='text'>3大产业联营保障人才质量电商-教育-人才产业化培养模式</p>
                </div>
            </div>
            <CenterBox>
                <div>
                    <p>选择513教育</p>
                    <p>四大优势</p>
                    <p>CHOOSE 513 ADVANTAGE</p>
                </div>
            </CenterBox>
        </Main>
    </Container>
}