import React from 'react'
import { Swiper } from 'swiper/react';

export default class extends React.Component{
    constructor(props){
        super(props)
        this.mySwiper = null
    }

     handleMouseOver = () => {
        if(this.mySwiper) this.mySwiper.autoplay.pause()
    }
     handleMouseLeave = () => {
        if(this.mySwiper) this.mySwiper.autoplay.run()
    }
     handleStart = (swiper) => {
        swiper.autoplay.pause()
    }
     handleEnd = (swiper) => {
        swiper.autoplay.run()   
    }
     handleSwiper = (swiper) => {
        this.mySwiper = swiper
        if(this.props.getSwiper) this.props.getSwiper(swiper)
    }

    render(){
        let {children,getSwiper,...rest} = this.props;
        return <div className='my-swiper-container'
          onMouseOver={()=>this.handleMouseOver()}
          onMouseLeave={()=>this.handleMouseLeave()}
          >
              <Swiper
              {...rest}
              onTouchStart={swiper=>{this.handleStart(swiper)}}
              onTouchEnd={swiper=>{this.handleEnd(swiper)}}
              onSwiper={this.handleSwiper}
              >
                  {children}
            </Swiper>
          </div>
    }
}