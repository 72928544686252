import AJAX from "api/api";
import { historyState } from "utils/memory";
import { storage } from "utils/dom";
import { buildEnvSelector } from "utils/utils";
function logout() {
  const { schoolId } = storage.parse().get("teacherUserInfo") || {};
  localStorage.clear();

  buildEnvSelector({
    development() {
      historyState.get().replace("/login");
    },
    production() {
      if (schoolId === 1) {
        historyState.get().replace("/login");
      } else {
        window.location.href = "/#/login";
      }
    },
  });
}

export default function (e) {
  if (e === 1) {
    return logout();
  }

  return AJAX.login.logOut().then((res) => {
    logout();
  });
}
