import React from 'react'
import styled from 'styled-components'

export const Title = styled.div`
    padding: 30px 0;
    p{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #28BBAC;
        line-height: 36px;
        span{
            font-size: 46px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #28BBAC;
            line-height: 36px;
        }
    }
    p::before{
        display: inline-block;
        content:'';
        width: 150px;
        height: 2px;
        background-color: #28BBAC;
        margin-right: 15px;
    }
    p::after{
        display: inline-block;
        content:'';
        width: 150px;
        height: 2px;
        background-color: #28BBAC;
        margin-left: 15px;
    }
`
export const Part3 = styled.div`
    position: relative; 
    max-width: 1694px;
    min-width: 1200px;
    padding-top: 30px;
    margin: 0 auto;
    .my-swiper-container{
        img{
            width: 100%;
        }
        .swiper-pagination,
        .swiper-button-next,
        .swiper-button-prev{
            display: none !important;
        }
    }
`
